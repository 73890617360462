.masonry-set-dimensions-2 .thumbnail{
  float: left;
}
.masonry-set-dimensions-2 .description{
  padding: 2rem;
  text-align: center;
  background: $white;
}
.masonry-set-dimensions-2 h5:last-child,
.masonry-set-dimensions-2 p:last-child{
  margin-bottom: 0;
}
.masonry-set-dimensions-2 .half.image-left .thumbnail,
.masonry-set-dimensions-2 .half.image-right .thumbnail{
  width: 50%;
}
.masonry-set-dimensions-2 .two-third.image-left .thumbnail,
.masonry-set-dimensions-2 .two-third.image-right .thumbnail{
  width: 66.66667%;
}
.masonry-set-dimensions-2 .half.image-top .thumbnail,
.masonry-set-dimensions-2 .half.image-bottom .thumbnail{
  width: 100%;
}
.masonry-set-dimensions-2 .two-third.image-top .thumbnail,
.masonry-set-dimensions-2 .two-third.image-bottom .thumbnail{
  width: 100%;
}
.masonry-set-dimensions-2 .half.image-right .thumbnail,
.masonry-set-dimensions-2 .two-third.image-right .thumbnail{
  float: right;
}
.masonry-set-dimensions-2 .image-left.no-description .thumbnail,
.masonry-set-dimensions-2 .image-right.no-description .thumbnail{
  width: 100%;
}

/* Description */
.masonry-set-dimensions-2 .item-description{
  margin-top: 0;
  height: 100%;
  background: #f9f9f9;
  text-align: center;
  display: table;
  position: relative;
}
.masonry-set-dimensions-2 .item-description-inner{
  height: 100%;
  padding: 1rem 3rem 3rem 3rem;
  display: table-cell;
  vertical-align: middle;
}

/* Description Positions */
.masonry-set-dimensions-2 .half.image-left .item-description{
  width: 50%;
  text-align: left;
  float: right;
}
.masonry-set-dimensions-2 .half.image-right .item-description{
  width: 50%;
  text-align: right;
  float: left;
}
.masonry-set-dimensions-2 .two-third.image-left .item-description{
  width: 33.33333%;
  text-align: left;
  float: right;
}
.masonry-set-dimensions-2 .two-third.image-right .item-description{
  width: 33.33333%;
  text-align: right;
  float: left;
}
.masonry-set-dimensions-2 .half.image-top .item-description{
  width: 100%;
  height: 50%;
  text-align: center;
}
.masonry-set-dimensions-2 .half.image-bottom .item-description{
  width: 100%;
  height: 50%;
  text-align: center;
}
.masonry-set-dimensions-2 .two-third.image-top .item-description{
  width: 100%;
  height: 33.33333%;
  text-align: center;
}

/* Item Arrows */
.masonry-set-dimensions-2 .item-description:after{
  width: 0;
  height: 0;
  margin-top: -10px;
  border: solid transparent;
  content: " ";
  position: absolute;
  pointer-events: none;
  border-color: #f9f9f9;
  border-width: 10px;
  top: 50%;
  z-index: 10;
}
.masonry-set-dimensions-2 .item-description:after{
  margin-left: -10px;
  border: solid transparent;
  border-bottom-color: #f9f9f9;
  border-width: 10px;
  top: auto;
  bottom: 100%;
  left: 50%;
}
.masonry-set-dimensions-2 .image-left .item-description:after  {
  margin-top: -10px;
  border: solid transparent;
  border-right-color: #f9f9f9;
  border-width: 10px;
  top: 50%;
  right: 100%;
  bottom: auto;
  left: auto;
}
.masonry-set-dimensions-2 .image-right .item-description:after {
  margin-top: -10px;
  border: solid transparent;
  border-left-color: #f9f9f9;
  border-width: 10px;
  top: 50%;
  bottom: auto;
  right: -20px;
  left: auto;
}
.masonry-set-dimensions-2 .image-bottom .item-description:after{
  margin-left: -10px;
  border: solid transparent;
  border-top-color: #f9f9f9;
  border-width: 10px;
  top: auto;
  bottom: -2rem;
  left: 50%;
}

/* Recent Slider */
.recent-slider .thumbnail{
  margin-bottom: 0;
}
.recent-slider .column.slider-column{
  padding-left: 0;
  padding-right: 0;
}
.recent-slider .recent-slider{
  padding: 0;
  margin-bottom: 0;
  background: none;
}
.recent-slider .previous-recent-slider,
.recent-slider .next-recent-slider{
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  line-height: 3rem;
  text-align: center;
  display: inline-block;
  border: 1px solid #ddd;
}
.recent-slider [class*="icon-"]{
  margin: 0;
}
.recent-slider .grid-description{
  width: 100%;
  height: 100%;
  padding: 0;
  display: block;
}

/* Single Project Media */
.portfolio-media,
.portfolio-media img,
.portfolio-media iframe,
.portfolio-media object{
  margin-bottom: 3rem;
}
.portfolio-media *:last-child{
  margin-bottom: 0;
}

/* Single Project Details */
.project-details h6{
  margin-bottom: 1rem;
}
.project-details ul{
  margin-bottom: 2rem;
}

/* Portfolio Queries */
@media only screen and (max-width: 960px){
  .recent-slider .column{
    width: 100%;
  }
  .recent-slider .grid-description{
    margin-bottom: 2rem;
  }
}
@media only screen and (max-width: 768px){
  .masonry-set-dimensions-2 .two-third.horizontal,
  .masonry-set-dimensions-2 .two-third.horizontal .thumbnail{
    width: 100% !important;
    height: auto !important;
  }
  .masonry-set-dimensions-2 .two-third.image-left .item-description,
  .masonry-set-dimensions-2 .two-third.image-right .item-description{
    width: 100%;
    float: left;
  }
  .masonry-set-dimensions-2 .two-third.image-left .item-description:after,
  .masonry-set-dimensions-2 .two-third.image-right .item-description:after{
    margin-left: -1rem;
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $white;
    border-width: 10px;
    top: auto !important;
    bottom: 100%;
    left: 50%;
  }
}
@media only screen and (max-width: 600px){
  .masonry-set-dimensions-2 .half.horizontal,
  .masonry-set-dimensions-2 .half.horizontal .thumbnail{
    width: 100% !important;
    height: auto !important;
  }
  .masonry-set-dimensions-2 .half.image-left .item-description,
  .masonry-set-dimensions-2 .half.image-right .item-description{
    width: 100%;
    float: left;
  }
  .masonry-set-dimensions-2 .image-left .item-description:after,
  .masonry-set-dimensions-2 .image-right .item-description:after{
    margin-left: -1rem;
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $white;
    border-width: 10px;
    top: auto !important;
    bottom: 100%;
    left: 50%;
  }
}