.overlay-info .project-title {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.1rem;
  text-transform: none;
  color: $white;
}

.overlay-info .project-description {
  margin-top: 1.1rem;
  display: block;
  font-size: 1.2rem;
  opacity: 1;
  text-transform: none;
  font-weight: 400;
}

/* Overlay */
.overlay-info {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.5);
}

.overlay-info > span > span {
  text-align: center;
  vertical-align: middle;
}

.overlay-info .project-title {
  margin-bottom: 0.8rem;
  display: block;
}

.overlay-fade-img-scale-out .overlay-info .overlay-info {
  opacity: 1;
}

.overlay-fade-img-scale-in .project-title {
  margin-bottom: 0;
}

.caption-below {
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
  border: none;
  opacity: 0.5;
}
