.single-post .post{
  margin-bottom: 0;
}
.single-post .post-content .post-media{
  margin-bottom: 3rem;
}
.single-post .post-content.with-background .post-media{
  margin-bottom: 0;
}
.single-post-tags{
  width: 100%;
  padding-top: 3rem;
  margin-top: 3rem;
  font-size: 1.2rem;
  border-top: 1px solid #ddd;
}

/* Post Tags */
.single-post-tags .tags-title{
  font-weight: bold;
  margin-top: 0;
}
.single-post-tags a{
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  display: inline-block;
  letter-spacing: 0.1rem;
}
