.mt-0{
  margin-top: 0;
}
.mt-5{
  margin-top: 0.5rem;
}
.mt-10{
  margin-top: 1rem;
}
.mt-20{
  margin-top: 2rem;
}
.mt-30{
  margin-top: 3rem;
}
.mt-40{
  margin-top: 4rem;
}
.mt-50{
  margin-top: 5rem;
}
.mt-60{
  margin-top: 6rem;
}
.mt-70{
  margin-top: 7rem;
}
.mt-80{
  margin-top: 8rem;
}
.mt-90{
  margin-top: 9rem;
}
.mt-100{
  margin-top: 10rem;
}
.mt-110{
  margin-top: 11rem;
}
.mt-120{
  margin-top: 12rem;
}
.mt-130{
  margin-top: 13rem;
}
.mt-140{
  margin-top: 14rem;
}
.mt-150{
  margin-top: 15rem;
}
.mb-0{
  margin-bottom: 0;
}
.mb-5{
  margin-bottom: 0.5rem;
}
.mb-10{
  margin-bottom: 1rem;
}
.mb-20{
  margin-bottom: 2rem;
}
.mb-30{
  margin-bottom: 3rem;
}
.mb-40{
  margin-bottom: 4rem;
}
.mb-50{
  margin-bottom: 5rem;
}
.mb-60{
  margin-bottom: 6rem;
}
.mb-70{
  margin-bottom: 7rem;
}
.mb-80{
  margin-bottom: 8rem;
}
.mb-90{
  margin-bottom: 9rem;
}
.mb-100{
  margin-bottom: 10rem;
}
.mb-110{
  margin-bottom: 11rem;
}
.mb-120{
  margin-bottom: 12rem;
}
.mb-130{
  margin-bottom: 13rem;
}
.mb-140{
  margin-bottom: 14rem;
}
.mb-150{
  margin-bottom: 15rem;
}

@media only screen and (max-width: 768px) {
  .mt-mobile-0{
    margin-top: 0;
  }
  .mt-mobile-5{
    margin-top: 0.5rem;
  }
  .mt-mobile-10{
    margin-top: 1rem;
  }
  .mt-mobile-20{
    margin-top: 2rem;
  }
  .mt-mobile-30{
    margin-top: 3rem;
  }
  .mt-mobile-40{
    margin-top: 4rem;
  }
  .mt-mobile-50{
    margin-top: 5rem;
  }
  .mt-mobile-60{
    margin-top: 6rem;
  }
  .mt-mobile-70{
    margin-top: 7rem;
  }
  .mt-mobile-80{
    margin-top: 8rem;
  }
  .mt-mobile-90{
    margin-top: 9rem;
  }
  .mt-mobile-100{
    margin-top: 10rem;
  }
  .mt-mobile-110{
    margin-top: 11rem;
  }
  .mt-mobile-120{
    margin-top: 12rem;
  }
  .mt-mobile-130{
    margin-top: 13rem;
  }
  .mt-mobile-140{
    margin-top: 14rem;
  }
  .mt-mobile-150{
    margin-top: 15rem;
  }
  .mb-mobile-0{
    margin-bottom: 0;
  }
  .mb-mobile-5{
    margin-bottom: 0.5rem;
  }
  .mb-mobile-10{
    margin-bottom: 1rem;
  }
  .mb-mobile-20{
    margin-bottom: 2rem;
  }
  .mb-mobile-30{
    margin-bottom: 3rem;
  }
  .mb-mobile-40{
    margin-bottom: 4rem;
  }
  .mb-mobile-50{
    margin-bottom: 5rem;
  }
  .mb-mobile-60{
    margin-bottom: 6rem;
  }
  .mb-mobile-70{
    margin-bottom: 7rem;
  }
  .mb-mobile-80{
    margin-bottom: 8rem;
  }
  .mb-mobile-90{
    margin-bottom: 9rem;
  }
  .mb-mobile-100{
    margin-bottom: 10rem;
  }
  .mb-mobile-110{
    margin-bottom: 11rem;
  }
  .mb-mobile-120{
    margin-bottom: 12rem;
  }
  .mb-mobile-130{
    margin-bottom: 13rem;
  }
  .mb-mobile-140{
    margin-bottom: 14rem;
  }
  .mb-mobile-150{
    margin-bottom: 15rem;
  }
}