.footer {
  position: relative;
  z-index: 1;

  width: 100%;

  color: $grey-40;
  background: $white;

  .row.flex > .column{
    flex-direction: column;
  }

}

.footer-top {
  padding-top: 6rem;
  padding-bottom: 1rem;

  .footer-logo {
    width: 9rem;
    margin-bottom: 3rem !important;
  }

}

.footer-bottom {
  padding: 0 0 2rem 0;
  background-color: transparent;

  &,
  a {
    color: $grey-40;
  }

  .footer-bottom-inner {
    padding-top: 2rem;
  }

  .copyright{
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }

  .social-list{
    margin-bottom: 1rem;

    a {
      font-size: 1.1rem;
      font-weight: normal;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }
  }
}


/* Footer Specific */
.footer .form-element {
  background-color: transparent;
  border-color: $grey-60;
  margin-bottom: 2rem;
  color: $grey-60;
}

.footer .form-element:focus,
.footer .form-element.required-field {
  background-color: transparent;
  box-shadow: none;
  border-color: $grey-80;
  color: $grey-80;
}

.footer .form-response {
  color: $grey-60;
  font-size: 1.2rem;
}

.footer ::-webkit-input-placeholder,
.footer :focus::-webkit-input-placeholder,
.footer ::-moz-placeholder,
.footer :focus::-moz-placeholder,
.footer :-ms-input-placeholder,
.footer :focus:-ms-input-placeholder {
  color: $grey-80;
}

.footer .widget {
  margin-bottom: 3rem;
  font-size: 1.5rem;
}



/* Footer Media Queries */
@media only screen and (max-width: 768px) {
  .footer-top > .row > .column{
    width: 100%;
  }
  [class*="footer-"] .footer-bottom .copyright,
  [class*="footer-"] .footer-bottom .social-list{
    display: block;
    float: none;
  }
}