.post{
  margin-bottom: 7rem;
}
.post:last-child{
  margin-bottom: 0 !important;
}
.post-content{
  position: relative;
  -webkit-transition-property: background, color;
  transition-property: background, color;
  -webkit-transition-duration: 600ms;
  transition-duration: 600ms;
}
.post-media ~ .post-content,
.post-media + .post-info-aside{
  margin-top: 3rem;
}
.post-content + .post-media{
  margin-top: 3rem;
}
.blog-masonry.masonry-set-dimensions .post,
.blog-masonry.masonry-set-dimensions .post-media{
  height: 100%;
}
.blog-masonry.masonry-set-dimensions .post-content{
  width: 100%;
  height: auto;
  padding: 0;
  color: $white;
  display: table;
  position: absolute;
  bottom: 0;
  z-index: 2;
}
.blog-masonry.masonry-set-dimensions .post-content:hover{
  background: rgba(0,0,0,0.8);
}
.blog-masonry.masonry-set-dimensions .post-content-inner{
  height: 100%;
  padding: 2rem;
  display: table-cell;
  vertical-align: bottom;
}
.post-content > *:last-child{
  margin-bottom: 0;
}
.post-content.with-background{
  padding: 2rem 3rem;
  margin-top: 0;
}
.post-content.with-background > *:first-child{
  margin-top: 0;
}
.blog-masonry.masonry-set-dimensions .post-content.with-background{
  background: rgba(0,0,0,0.2);
}
.blog-masonry.masonry-set-dimensions .post-media:hover .post-content{
  background: rgba(0,0,0,0.8);
}
.post-info{
  margin-bottom: 3rem;
}
.blog-masonry.masonry-set-dimensions .post-info{
  margin-bottom: 1rem;
}
.post-info{
  padding: 0;
  margin-right: 0.2rem;
  margin-top: 0;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: inline-block;
  border: none;
}
.post-info span:first-child{
  margin-left: 0;
}
.post-info span:last-child{
  margin-right: 0;
}
.post-info [class*="icon-"]{
  display: inline-block;
  position: relative;
  z-index: 1;
}

.post-content .read-more{
  margin-bottom: 0;
}

/* Post Media Elements */
.post-media {
  max-width: 114rem;
}
.post-media > *{
  float: none;
  margin-bottom: 0;
}
.blog-masonry.masonry-set-dimensions .post-media{
  position: relative;
  overflow: hidden;
}
.blog-masonry.masonry-set-dimensions.no-margins .post-media{
  position: static;
  overflow: hidden;
}
.blog-regular .post-media .post-slider{
  width: 82.5rem;
  height: 55rem;
}
.blog-single-post .post-media .post-slider,
.blog-masonry .post-media .post-slider{
  width: 82.5rem;
  height: 55rem;
}
.blog-wide .post-media .post-slider{
  width: 111rem;
  height: 74rem;
}

/* Mejs */
.post-media .mejs-audio,
.post-media .mejs-audio .mejs-controls,
.post-media .mejs-audio .mejs-container .mejs-button,
.post-media .mejs-audio .mejs-button.mejs-play,
.post-media .mejs-audio .mejs-button.mejs-pause,
.post-media .mejs-audio .mejs-button.mejs-mute,
.post-media .mejs-audio .mejs-controls div.mejs-time-rail,
.post-media .mejs-audio .mejs-controls div.mejs-horizontal-volume-slider{
  height: 4.9rem !important;
}
.post-media .mejs-audio .mejs-controls .mejs-time{
  margin-top: 1rem;
}
.post-media .mejs-audio .mejs-time-rail .mejs-time-total{
  margin-top: 2.1rem;
}
.post-media .mejs-audio .mejs-horizontal-volume-slider .mejs-horizontal-volume-total,
.post-media .mejs-audio .mejs-horizontal-volume-slider .mejs-horizontal-volume-current{
  top: 2.1rem;
}

/* Common Buttons */
.post-media .mejs-audio .mejs-button button{
  margin: 1.6rem 0.7rem;
}

/* Play */
.post-media .mejs-audio .mejs-button.mejs-play,
.post-media .mejs-audio .mejs-button.mejs-pause{
  width: 4.4rem !important;
}
.post-media .mejs-audio .mejs-button.mejs-play button,
.post-media .mejs-audio .mejs-button.mejs-pause button{
  margin: 1.6rem 1.4rem;
}

/* Masonry Layout
   For fixed size grid items use
   .blog-masonry.masonry-set-dimensions
*/
.blog-masonry.no-padding-top{
  padding-top: 1rem;
}
.blog-masonry.no-padding-bottom{
  padding-bottom: 0.5rem;
}
.blog-masonry.full-width.no-margins{
  padding: 0;
}
.blog-masonry [class*="content-grid"]{
  margin-bottom: -3rem;
}
.blog-masonry.masonry-set-dimensions [class*="content-grid"]{
  margin-bottom: -1.5rem;
}
.blog-masonry.no-margins [class*="content-grid"],
.blog-masonry.no-margins [class*="content-grid"] .grid-item{
  padding: 0 !important;
  margin: 0 !important;
}
.blog-masonry > .row > .column,
.blog-masonry [class*="content-grid"]{
  padding-left: 0;
  padding-right: 0;
}
.blog-masonry [class*="content-grid"]{
  margin-left: 0;
  margin-right: 0;
}
.blog-masonry.full-width [class*="content-grid"] {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.blog-masonry [class*="content-grid"] .grid-item{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.blog-masonry .grid-item .video,
.blog-masonry .grid-item .video-container{
  margin-bottom: 0;
}

/* Titles */
.blog-regular h2.post-title,
.blog-wide h2.post-title,
.blog-masonry h2.post-title,
.blog-single-post h2.post-title{
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Small Margins */
.blog-masonry.small-margins [class*="content-grid"]{
  padding-left: 0;
  padding-right: 0;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.blog-masonry.small-margins > .row > .column{
  padding-left: 1rem;
  padding-right: 1rem;
}
.blog-masonry.small-margins.no-margins > .row > .column{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.blog-masonry.small-margins.full-width > .row > .column{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.blog-masonry.small-margins.no-margins.full-width > .row > .column{
  padding-left: 0rem;
  padding-right: 0rem;
}
.blog-masonry.small-margins.full-width [class*="content-grid"]{
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
.blog-masonry.small-margins [class*="content-grid"]{
  padding-left: 0;
  padding-right: 0;
  margin-top: -0.5rem;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: -0.5rem;
}
.blog-masonry.small-margins [class*="content-grid"] .grid-item{
  margin-bottom: 0;
  padding: 0.5rem;
  position: relative;
}

/* No Margins */
.blog-masonry.no-margins > .row > .column{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

/* Full Width */
.blog-masonry.full-width.no-margins > .row > .column{
  padding-left: 0;
  padding-right: 0;
}
.blog-masonry.full-width .row{
  max-width: 100%;
}

/* Sidebar */
.sidebar.left .sidebar-inner{
  padding-right: 1rem;
}
.sidebar.right .sidebar-inner{
  padding-left: 1rem;
}
.sidebar.left .sidebar-inner,
.sidebar.right .sidebar-inner{
  text-align: left;
}
.sidebar .widget:first-child .widget-title{
  margin-top: 0;
}

/* Sidebar & Footer */
.widget .post-info{
  display: block;
  margin-bottom: 0;
  line-height: 1;
}

/* Blog Media Queries */
@media only screen and (max-width: 1140px) {
  .blog-wide .post-media .post-slider{
    width: 93rem;
    height: 62rem;
  }
  .blog-regular .post-media .post-slider{
    width: 69rem;
    height: 46rem;
  }
  .blog-masonry [class*="content-grid"] .grid-item{
    width: 33.33333%;
  }
}
@media only screen and (max-width: 960px) {
  .blog-wide .post-media .post-slider{
    width: 73rem;
    height: 48.7rem;
  }
  .blog-regular .post-media .post-slider{
    width: 54rem;
    height: 36rem;
  }
  .sidebar .sidebar-inner{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .blog-masonry [class*="content-grid"] .grid-item{
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .blog-regular .post-media .post-slider,
  .blog-wide .post-media .post-slider{
    width: 57rem;
    height: 38rem;
  }
  .post-info span.show-on-mobile,
  .post-info .show-on-mobile a,
  .post-info [class*="icon-"]{
    display: inline-block !important;
  }
}
@media only screen and (max-width: 600px) {
  .blog-regular .post-media .post-slider,
  .blog-wide .post-media .post-slider{
    width: 45rem;
    height: 30rem;
  }
  .blog-masonry [class*="content-grid"] .grid-item{
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .blog-regular .post-media .post-slider,
  .blog-wide .post-media .post-slider{
    width: 32rem;
    height: 21.3rem;
  }
}