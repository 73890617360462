
.mobile .header {
  position: static !important;
}

.mobile .header-fixed-on-mobile .header-inner {
  width: 100%;
  position: fixed !important;
  left: 0;
  top: 0;
}

.mobile .header,
.mobile .header .header-inner {
  height: 8rem !important;
}

.mobile .header .nav-bar,
.mobile .header .nav-bar-inner {
  height: 100%;
}

/* Logo Mobile */
.mobile .header .logo {
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
  display: table;
  float: left;
}

.mobile .header .logo-inner {
  display: table-cell;
  vertical-align: middle;
}

.mobile .header .logo-inner a {
  display: block;
  position: relative;
}

.mobile .header .logo a:first-child {
  display: table-cell;
  vertical-align: middle;
}

.mobile .header .logo a + a {
  display: none !important;
}

.mobile .header .navigation-show {
  display: block !important;
  visibility: visible !important;
}

.mobile .header .header-inner,
.mobile .header .logo,
.mobile .header .navigation-show,
.mobile .header .navigation-show a span {
  transition: none;
}

@media only screen and (max-width: 960px) {
  .header {
    background: none;
    position: static !important;
  }
  .header-fixed-on-mobile .header-inner {
    width: 100%;
    position: fixed !important;
    left: 0;
    top: 0;
  }
  .header,
  .header .header-inner {
    height: 8rem !important;
  }
  .header-transparent .header-inner {
    background: $blue;
    border-bottom: 1px solid $dark-blue;
  }
  .header .nav-bar,
  .header .nav-bar-inner {
    height: 100%;
  }
  .header .logo-inner a {
    display: block;
    position: relative;
  }
  .header .logo a + a {
    display: none !important;
  }
  .header .header-inner,
  .header .logo,
  .header .navigation-show,
  .header .navigation-show a span {
    transition: none;
  }
  .header .navigation-show {
    display: block !important;
    visibility: visible !important;
  }
}

