.header .logo-inner {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

.header .logo-inner {
  overflow: hidden;
}

/* Logo Initial Width */
.header .logo {
  width: 22rem;
}

/* Compacted Header Logo Width */
.header-compact .logo {
  width: 16rem;
}

/* Logo Styling */
.header .logo a {
  display: block !important;
  position: absolute;
  top: 0;
  transition-property: opacity, background, color, visibility, transform;
}

.header .logo a:hover {
  opacity: 0.6 !important;
}

.header .logo img {
  position: relative;
  top: -.3rem;
  width: 100%;
  height: auto;
  max-height: 100%;
}

/* Logo Swap - when header background changes*/
.header .logo a:first-child {
  display: none;
}

.header .logo a:last-child {
  display: table-cell;
}

.mobile .header .logo a:first-child,
.header-background .logo a:first-child {
  display: table-cell;
}

.header-in .logo a,
.header-out .logo a {
  -webkit-transition-duration: 0ms;
  transition-duration: 0ms;
}

.header .logo a:first-child {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.header .logo a + a {
  display: none !important;
}

/* Transparent Header Show Light Logo */
.header-fixed .logo a:first-child {
  opacity: 0;
  -webkit-transform: translateY(5rem);
  transform: translateY(5rem);
}

.header-fixed .logo a + a {
  display: block !important;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/* Header Background Logo Swap to Dark Logo - On Scroll! */
.header-background .logo a:first-child {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.header-background .logo a + a {
  display: block !important;
  opacity: 0;
  -webkit-transform: translateY(-5rem);
  transform: translateY(-5rem);
}

/* Logo Mobile Header & Breakpoints */
.mobile .header .logo a:first-child {
  display: block !important;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

@media only screen and (max-width: 960px) {
  .header .logo-inner {
    display: table-cell;
    vertical-align: middle;
  }
  .header .logo a {
    position: relative;
  }
  .header .logo a:first-child {
    opacity: 1;
    top: 0;
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }
  .header .logo a:last-child {
    display: none !important;
  }
}