.grid-container{
  position: relative;
}
.grid-container .grid{
  max-width: 100%;
  min-height: 30rem;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: -3rem;
  visibility: hidden;
  z-index: 1;
}
.masonry.fade-in-progressively .grid-item,
.masonry.fade-in-progressively .masonry-stamp{
  opacity: 0;
}
.masonry .filtering{
  -webkit-transition-property: height;
  transition-property: height;
  -webkit-transition-duration: 0.8s;
  transition-duration: 0.8s;
}
.masonry .tm-loader{
  top: 6rem;
}

/* Grid - 30px Gutter */
.grid-container > .row > .column{
  padding-left: 0;
  padding-right: 0;
}
.grid-container .grid{
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
.grid-container .grid .grid-item{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.grid-container .thumbnail{
  margin-bottom: 0;
  float: none;
}

/* Grid - 10px Gutter Grid */
.grid-container.small-margins > .row > .column{
  padding-left: 1rem;
  padding-right: 1rem;
}
.grid-container.small-margins.full-width > .row > .column{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.grid-container.small-margins.no-margins.full-width > .row > .column{
  padding-left: 0rem;
  padding-right: 0rem;
}
.grid-container.small-margins.full-width .grid{
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
.grid-container.small-margins .grid{
  padding-left: 0;
  padding-right: 0;
  margin-top: -0.5rem;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: -0.5rem;
}
.grid-container.small-margins .grid .grid-item{
  margin-bottom: 0;
  padding: 0.5rem;
  position: relative;
}

/* Grid - No Gutter  */
.grid-container.no-margins > .row > .column{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.grid-container.no-margins .grid{
  margin-bottom: 0;
}
.grid-container.no-margins .grid,
.grid-container.no-margins .grid .grid-item{
  padding: 0 !important;
  margin: 0 !important;
}

/* Full Width Grid */
.grid-container.full-width .row{
  max-width: 100%;
}
.grid-container.full-width .grid{
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.grid-container.full-width > .row > .column{
  padding-left: 0;
  padding-right: 0;
}
.grid-container.full-width.no-margins{
  padding: 0 !important;
}
.grid-container.full-width.no-padding-bottom .grid{
  margin-bottom: 0;
}

/* Special Bottom Padding */
.masonry-set-dimensions.full-width.no-padding-bottom{
  padding-bottom: 1.5rem !important;
}
.masonry-set-dimensions.full-width.small-margins.no-padding-bottom{
  padding-bottom: 0.5rem !important;
}

/* Fixed Dimension Grid
   Wrap any content grid in .masonry-set-demensions
   to set grid item with and height. Use .large and .portrait on
   .grid-item to specify grid item format.
*/
.masonry-set-dimensions{
  padding-top: 7rem;
  padding-bottom: 6rem;
}

/* External Paddding */
.masonry-set-dimensions.full-width .row{
  max-width: 100%;
}
.masonry-set-dimensions.full-width .grid{
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
.masonry-set-dimensions.full-width > .row > .column {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.masonry-set-dimensions.small-margins.full-width > .row > .column{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.masonry-set-dimensions.no-margins .grid,
.masonry-set-dimensions.no-margins .grid .grid-item{
  padding: 0 !important;
  margin: 0 !important;
}
.masonry-set-dimensions.full-width.no-margins{
  padding: 0;
}

/* Grid Fixed Dimensions - 30px Gutter */
.masonry > .row > .column,
.masonry-set-dimensions > .row > .column{
  padding-left: 0;
  padding-right: 0;
}
.masonry-set-dimensions .grid{
  padding-left: 0;
  padding-right: 0;
  margin-top: -1.5rem;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: -1.5rem;
}
.masonry-set-dimensions .grid .grid-item{
  margin-bottom: 0;
  padding: 1.5rem;
  position: relative;
}

/* Grid Fixed Dimensions - 10px Gutter */
.masonry-set-dimensions.small-margins > .row > .column{
  padding-left: 1rem;
  padding-right: 1rem;
}
.masonry-set-dimensions.small-margins .grid{
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.masonry-set-dimensions.small-margins .grid .grid-item{
  padding: 0.5rem;
}

/* Grid Fixed Dimensions - No Gutter */
.masonry-set-dimensions.no-margins > .row > .column{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.masonry-set-dimensions.no-margins.full-width > .row > .column{
  padding-left: 0rem;
  padding-right: 0rem;
}
.masonry-set-dimensions .thumbnail{
  height: 100%;
  overflow: hidden;
  margin-bottom: 0;
}
.masonry-set-dimensions .grid-item img{
  width: 100%;
}

/* Grid Item Content Wrappers */
.masonry-set-dimensions .content-outer{
  width: 100%;
  height: 100%;
  display: table;
  position: relative;
  bottom: 0;
}
.masonry-set-dimensions .thumbnail + .content-outer{
  position: absolute;
}
.masonry-set-dimensions .content-inner{
  width: 100%;
  height: 100%;
  padding: 3rem;
  display: table-cell;
  vertical-align: middle;
}
.masonry-set-dimensions .content-inner > *:first-child{
  margin-top: 0;
}
.masonry-set-dimensions .content-inner > *:last-child{
  margin-bottom: 0;
}

/* Grid Item Media */
.masonry-set-dimensions .content-slider,
.masonry-set-dimensions video,
.masonry-set-dimensions iframe{
  width: 100% !important;
  height: 100% !important;
}
.masonry-set-dimensions .content-slider img{
  width: auto;
}
.masonry-set-dimensions .mejs-container{
  width: 20rem;
  height: 3rem;
  margin-left: -10rem;
  margin-top: -1.5rem;
  position: absolute;
  left: 50%;
  top: 50%;
}

/* Masonry Stamp */
.masonry-stamp{
  padding: 1.5rem;
}
.small-margins .masonry-stamp{
  padding: 0.5rem;
}
.no-margins .masonry-stamp{
  padding: 0;
}

.grid-filter-menu{
  padding-top: 3.5rem;
  padding-bottom: 0rem;
  text-align: center;
}
.grid-filter-menu.left{
  text-align: left;
}
.grid-filter-menu.left li:first-child a{
  padding-left: 0;
  margin-left: 0;
}
.grid-filter-menu.right{
  text-align: right;
}
.grid-filter-menu.right li:last-child a{
  padding-right: 0;
  margin-right: 0;
}
.grid-filter-menu ul{
  width: 100%;
  padding-top: 3.5rem;
  padding-bottom: 0rem;
  margin-bottom: 0;
  list-style: none;
}
.grid-filter-menu li{
  padding: 0 2rem;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1;
  text-transform: uppercase;
}
.grid-filter-menu a{
  padding: 1rem 0.8rem;
  -webkit-transition-property: background, border-color, color, opacity;
  transition-property: background, border-color, color, opacity;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
.grid-filter-menu a:hover{
  color: #333;
}
.grid-filter-menu a.active{
  color: #333;
  border-bottom: 1px solid #333;
}

/* Description */
.grid .project-title,
.grid .project-description{
  display: block;
}
.grid .project-title + .project-description{
  margin-top: 0.5rem;
}
.grid .item-description{
  width: 100%;
  margin-top: 2rem;
}
.grid .item-description .project-title{
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.grid .item-description *:last-child{
  margin-bottom: 0rem;
}

/* Isotope Item */
.isotope-item {
  z-index: 2;
}
.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

/* Isotope Animation Classes */
.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
.isotope {
  -webkit-transition-property: height, width;
  transition-property: height, width;
}
.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  transition-property:         transform, opacity;
}
.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

/* Grid Breakpoints */
@media only screen and (max-width: 1300px){
  .grid-container .content-grid-5 .grid-item,
  .grid-container .content-grid-6 .grid-item{
    width: 25%;
  }
}
@media only screen and (max-width: 1140px){
  .grid-container .grid .grid-item{
    width: 33.33333%;
  }
  .content-inner .grid-container .grid .grid-item,
  .grid-container .content-grid-2 .grid-item,
  .grid-container.full-width.small-margins .content-grid-2 .grid-item,
  .grid-container.full-width.no-margins .content-grid-2 .grid-item{
    width: 50%;
  }
}
@media only screen and (max-width: 960px){
  .content-inner .grid-container .grid .grid-item,
  .grid-container .grid .grid-item{
    width: 50%;
  }
  .grid-container.full-width.small-margins .grid .grid-item,
  .grid-container.full-width.no-margins .grid .grid-item{
    width: 33.33333%;
  }
}
@media only screen and (max-width: 768px){
  .grid-container .grid .grid-item.large,
  .grid-container .grid .grid-item.portrait.large{
    width: 100%;
  }
  .grid-container.full-width .grid .grid-item,
  .grid-container.full-width.small-margins .grid .grid-item,
  .grid-container.full-width.no-margins .grid .grid-item{
    width: 50%;
  }
}
@media only screen and (max-width: 600px){
  .content-inner .grid-container .grid .grid-item,
  .grid-container .grid .grid-item,
  .grid-container.full-width .grid .grid-item{
    width: 100%;
  }
}
@media only screen and (max-width: 480px){
  .grid-container.full-width.small-margins .grid .grid-item,
  .grid-container.full-width.no-margins .grid .grid-item{
    width: 100%;
  }
}
