[class*="pu-"],
[class*="pd-"]{
  position: relative;
}
.pd-10{
  top: 1rem;
}
.pd-20{
  top: 2rem;
}
.pd-30{
  top: 3rem;
}
.pd-40{
  top: 4rem;
}
.pd-50{
  top: 5rem;
}
.pd-60{
  top: 6rem;
}
.pd-70{
  top: 7rem;
}
.pd-80{
  top: 8rem;
}
.pd-90{
  top: 9rem;
}
.pd-100{
  top: 10rem;
}
.pd-110{
  top: 11rem;
}
.pd-120{
  top: 12rem;
}
.pd-130{
  top: 13rem;
}
.pd-140{
  top: 14rem;
}
.pd-150{
  top: 15rem;
}
.pu-10{
  top: -1rem;
}
.pu-20{
  top: -2rem;
}
.pu-30{
  top: -3rem;
}
.pu-40{
  top: -4rem;
}
.pu-50{
  top: -5rem;
}
.pu-60{
  top: -6rem;
}
.pu-70{
  top: -7rem;
}
.pu-80{
  top: -8rem;
}
.pu-90{
  top: -9rem;
}
.pu-100{
  top: -10rem;
}
.pu-110{
  top: -11rem;
}
.pu-120{
  top: -12rem;
}
.pu-130{
  top: -13rem;
}
.pu-140{
  top: -14rem;
}
.pu-150{
  top: -15rem;
}

/* Pull-down */
@media only screen and (max-width: 768px) {
  [class*="pu-"],
  [class*="pd-"]{
    top: 0;
  }
}