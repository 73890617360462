.pagination-previous.disabled,
.pagination-next.disabled,
.pagination-previous.disabled:hover,
.pagination-next.disabled:hover{
  transition: none;
  cursor: default;
  background: none !important;
}

/* Pagination 1 */
.pagination-1 small{
  font-size: 1.3rem;
  display: block;
}
.pagination-1 small span{
  font-size: 1.3rem;
  display: inline-block;
}
.pagination-1 [class*="icon-"]{
  height: 3.6rem;
  font-size: 3.6rem;
  display: inline-block;
}
.pagination-1 .pagination-previous{
  text-align: left;
}
.pagination-1 .pagination-previous [class*="icon-"]{
  margin-right: 1rem;
  float: left;
}
.pagination-1 .pagination-next{
  text-align: right;
}
.pagination-1 .pagination-next [class*="icon-"]{
  margin-left: 1rem;
  float: right;
}
.pagination-1 span{
  font-size: 2rem;
  font-weight: bold;
  display: block;
}
.pagination-1 a.disabled,
.pagination-1 a.disabled:hover{
  color: #ddd;
}
.pagination-1 .return-to-index,
.pagination-1 .page-list{
  margin-top: 0.3rem;
  margin-bottom: 0;
  font-size: 1.7rem;
  text-align: center;
  font-weight: bold;
}
.pagination-1 .page-list li{
  padding: 0 0.7rem;
}

/* Pagination 2 */
.section-block.pagination-2{
  padding-top: 0;
  padding-bottom: 0;
}
.pagination-2 .column,
.pagination-2 .column:hover{
  background-size: cover;
  background-position: center;
  /* safari fix */
  -webkit-transform: translate(0,0);
}
.pagination-2 .pagination-previous,
.pagination-2 .pagination-next{
  padding-top: 7rem;
  padding-bottom: 7rem;
  display: block;
  position: relative;
}
.pagination-2 .pagination-previous{
  padding-left: 3rem;
  padding-right: 5rem;
  background: #f9f9f9;
  text-align: right;
}
/*
.pagination-2 .pagination-previous:after{
	width: 1px;
	height: 4rem;
	background: #ddd;
	position: absolute;
	content: "";
	top: 50%;
	right: 0;
	margin-top: -2rem;
}*/
.pagination-2 .pagination-next{
  padding-right: 3rem;
  padding-left: 5rem;
  background: #eee;
  text-align: left;
}
.pagination-2 a:hover{
  background: #ff0000;
  /*background: rgba(0,0,0,0.3) !important;*/
}
.pagination-2 small{
  font-size: 1.3rem;
  display: block;
}
.pagination-2 small span{
  font-size: 1.3rem;
  display: inline-block;
}
.pagination-2 [class*="icon-"]{
  height: 3.6rem;
  font-size: 3.6rem;
  display: inline-block;
}
.pagination-2 .pagination-previous [class*="icon-"]{
  margin-left: 1rem;
  float: right;
}
.pagination-2 .pagination-next [class*="icon-"]{
  margin-right: 1rem;
  float: left;
}
.pagination-2 span{
  font-size: 2rem;
  font-weight: bold;
  display: block;
}
.pagination-2 a.disabled,
.pagination-2 a.disabled:hover{
  color: #ddd;
}

/* Pagination 3 */
.pagination-3 ul{
  margin: 0;
  text-align: center;
}
.pagination-3 ul li{
  display: inline-block;
}
.pagination-3 a{
  width: 4rem;
  height: 4rem;
  margin: 0;
  padding: 1rem;
  display: block;
  border: 1px solid #ddd;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.8rem;
  text-align: center;
}
.pagination-3 a.current,
.pagination-3 a:hover{
  background: #333;
  color: $white;
  border: transparent;
}
.pagination-3 li:first-child{
  float: left;
}
.pagination-3 li:last-child{
  float: right;
}
.pagination-3 a.disabled,
.pagination-3 a.disabled:hover{
  color: #ddd;
  border-color: #ddd;
}

/* Pagination 4 */
.pagination-4{
  text-align: center;
}
.pagination-4 .button{
  width: auto;
  margin: 0 0.5rem;
  font-size: 1.6rem;
  font-weight: bold;
}
.pagination-4 .button.disabled,
.pagination-4 .button.disabled:hover{
  color: #ddd;
  background: none;
  border-color: #ddd;
}
.pagination-4 .button [class*="icon-"]:before{
  margin: 0;
}
.pagination-4 .pagination-previous [class*="icon-"],
.pagination-4 .pagination-next [class*="icon-"]{
  display: none;
}

/* Pagination 5 */
.pagination-5{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-top: 1px solid #ddd;
}
.pagination-5 ul{
  margin: 0;
  text-align: center;
}
.pagination-5 ul li{
  display: inline-block;
  margin-left: -0.4rem;
}
.pagination-5 a{
  width: 6rem;
  height: 6rem;
  color: #333;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 6rem;
  display: block;
}
.pagination-5 a.current{
  color: #999;
}
.pagination-5 a:hover{
  background: #333;
  color: $white;
}
.pagination-5 li:first-child{
  float: left;
}
.pagination-5 li:last-child{
  float: right;
}
.pagination-5 a.disabled,
.pagination-5 a.disabled:hover{
  color: #ddd;
  border-color: #ddd;
}
.pagination-5 a.pagination-previous,
.pagination-5 a.pagination-next{
  width: auto;
  padding: 0 2rem;
  font-weight: bold;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.pagination-5 a.pagination-previous{
  padding-right: 3.5rem
}
.pagination-5 a.pagination-next{
  padding-left: 3.5rem
}
.pagination-5 a span{
  width: auto;
  float: left;
}
.pagination-5 a [class*="icon-"]{
  height: 100%;
  margin-right: 0;
  line-height: 6rem;
  font-size: 3.2rem;
}

/* Pagination Media Queries */
@media only screen and (max-width: 960px) {
  .blog [class*="pagination-"] > .row > .column {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .pagination-1 .pagination-previous,
  .pagination-1 .return-to-index,
  .pagination-1 .page-list{
    padding-bottom: 3.8rem;
    margin-bottom: 3.8rem;
    border-bottom: 1px solid #ddd;
    display: block;
  }
  .pagination-1 .return-to-index,
  .pagination-1 .page-list,
  .pagination-1 .pagination-next{
    text-align: left;
  }
  .pagination-1 .pagination-previous [class*="icon-"]{
    margin-right: 0;
    float: right;
  }
  .pagination-1 .pagination-next [class*="icon-"]{
    margin-left: 0;
  }
  .pagination-2 [class*="pagination-"]{
    padding: 4rem 1.5rem;
  }
  .pagination-2 .pagination-previous{
    text-align: left;
  }
  .pagination-2 .pagination-previous:after{
    width: 10rem;
    height: 1rem;
    bottom: 0;
  }
  .pagination-2 .pagination-previous [class*="icon-"]{
    margin-left: 0;
    margin-right: 1rem;
    float: left;
  }
  .pagination-5 ul li a.pagination-previous,
  .pagination-5 ul li a.pagination-next{
    width: 6rem;
    padding: 0;
    text-align: center;
  }
  .pagination-5 ul li a span{
    float: none;
  }
  .pagination-5 ul li a.pagination-previous span:last-child{
    display: none;
  }
  .pagination-5 ul li a.pagination-next span:first-child{
    display: none;
  }
}
@media only screen and (max-width: 490px) {
  .pagination-1,
  .pagination-3,
  .pagination-4{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .pagination-4 span{
    display: none;
  }
  .pagination-4 .back-to-index [class*="icon-"],
  .pagination-4 .pagination-previous [class*="icon-"],
  .pagination-4 .pagination-next [class*="icon-"]{
    display: block;
  }
  .pagination-3 ul li,
  .pagination-5 ul li{
    display: none;
  }
  .pagination-3 ul li:first-child,
  .pagination-3 ul li:last-child,
  .pagination-5 ul li:first-child,
  .pagination-5 ul li:last-child{
    display: inline-block;
  }
}
