body,
.wrapper,
.content,
.featured-media:after,
.section-block:not([class*="bkg-"]),
.fullscreen-sections-wrapper {
  background-color: $white;
}

/* greyscale */
.wrapper-inner {
  filter: none;
  transition: 350ms ease-in-out;
}
