.form-element[class*="border-"]{
  background: $white;
}
.form-honeypot{
  display: none;
}

/* Contact Forms
*  Add contact-form-container to any section
*  or add a full prestyled section
*/
.contact-form-container{
  position: relative;
  margin-bottom: 3rem;
}
.contact-form-container .contact-form{
  position: relative;
}
.contact-form-container .row{
  margin-left: -1rem;
  margin-right: -1rem;
}
.contact-form-container [class*="width-"]{
  padding-left: 1rem;
  padding-right: 1rem;
}
.contact-form-container .form-element{
  margin-bottom: 2rem;
}
.contact-form-container .form-response{
  font-size: 1.2rem;
  line-height: 1.3;
  color: $grey-60;
  position: absolute;
  bottom: 0;
}
.contact-form-container input[type=submit]{
  margin-bottom: 0;
}
.contact-form-container input[disabled=disabled][type=submit] {
  opacity: 0.6;
}

/* Contact 1 */
.contact-1 h2{
  margin-bottom: 3rem;
}
.contact-1 .contact-form-container{
  margin-bottom: 0;
}
.contact-1 .contact-form-container .form-response{
  bottom: -3rem;
}

/* Contact 2 */
.contact-2 .contact-form-container .form-response{
  bottom: -3rem;
}

/* Singup Forms
*  Add signup-form-container to any section
*  or add a full prestyled section
*/
.signup-form-container{
  position: relative;
  margin-bottom: 3rem;
}
.signup-form-container .signup-form{
  position: relative;
}
.signup-form-container .row{
  margin-left: -1rem;
  margin-right: -1rem;
}
.signup-form-container [class*="width-"]{
  padding-left: 1rem;
  padding-right: 1rem;
}
.signup-form-container .form-element{
  margin-bottom: 2rem;
}
.signup-form-container .form-response{
  font-size: 1.2rem;
  line-height: 1.3;
  position: absolute;
  bottom: 0;
  -ms-word-break: break-all;
  word-break: break-word;
}
.signup-form-container input[type=submit]{
  margin-bottom: 2rem;
}
.signup-form-container input[disabled=disabled][type=submit] {
  opacity: 0.6;
}
.signup-form-container.boxed{
  padding: 4rem;
}

/* Sign-up 1 */
.signup-1 .signup-form-container{
  margin-bottom: 0;
}
.signup-1 .signup-form-container .form-response{
  bottom: -1.3rem;
}

/* Signup 2 */
.signup-2{
  /*color: $white;*/
  background-image: url(../images/slider/slide-5-fw.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}
.signup-2 .signup-form-container{
  padding: 4rem;
  margin-bottom: 0;
  background: rgba(0,0,0,.5);
}
.signup-2 .signup-form-container .form-response{
  bottom: 2.5rem;
}


.signup-1 .form-submit,
.signup-2 .form-submit{
  width: auto;
}

/* Signup 3 */
.signup-3{
  /*color: $white;*/
  background-image: url(../images/slider/slide-5-fw.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}
.signup-3 .signup-inner{
  background: rgba(0,0,0,0);
}
.signup-3 .row{
  text-align: center;
}
.signup-3 .signup-form-container{
  width: 30rem;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
.signup-3 .form-element{
  width: 100%;
}
.signup-3 .signup-form-container .form-response{
  width: 100%;
  bottom: -1.3rem;
}

/* Form Media Queries */
@media only screen and (max-width: 768px){
  .signup-form-container input[type=submit]{
    width: auto;
  }
}
@media only screen and (max-width: 480px){
  .signup-3 .signup-form-container{
    width: 100%;
  }
}
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (		 min-device-pixel-ratio: 2),
only screen and (			min-resolution: 192dpi),
only screen and (				min-resolution: 2dppx) {
  .signup-2{
    background-image:url(../images/slider/slide-5-fw@2x.jpg);
  }
}
