.section-block.fullscreen-sections-wrapper,
.section-block.fullscreen-section{
  padding-top: 0;
  padding-bottom: 0;
}
.fullscreen-sections-wrapper{
  position: relative;
  z-index: 0;
}
.fullscreen-section{
  width: 100%;
  height: 100%;
  height: 100vh;
  display: table;
  table-layout: fixed;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.fullscreen-section.in-view{
  z-index: 1;
}
.fullscreen-section .background-image{
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 0;
}
.fullscreen-section.background-fixed .background-image,
.fullscreen-section .background-slider-wrapper{
  width: 100%;
  height: 100vh;
  position: absolute;
  background-attachment: fixed;
  z-index: 0;
}
.fullscreen-section .background-slider-wrapper{
  height: 100%;
}
.fullscreen-section.background-fixed .background-slider-wrapper{
  position: fixed;
}

/* webkit specific to achieve higher frame rate */
.webkit .fullscreen-section .background-image-wrapper{
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-backface-visibility: hidden;
  top: 0;
  left: 0;
}
.webkit .fullscreen-section.background-fixed .background-image-wrapper{
  position: fixed;
}
.webkit .fullscreen-section.background-fixed .background-image{
  position: relative;
  background-attachment: scroll;
}

/* Edge fix */
.ie-browser.webkit .fullscreen-section.background-fixed .background-image-wrapper{
  height: 100vh;
  position: absolute !important;
}
.ie-browser.webkit .fullscreen-section.background-fixed .background-image{
  background-attachment: fixed;
}

/* Fixed position fix when content is transformed */
.webkit.side-nav-open .background-fixed .background-image-wrapper{
  position: absolute;
}

/* Mobile */
.mobile .fullscreen-section{
  height: auto;
}
.mobile .fullscreen-section .background-image-wrapper,
.mobile .fullscreen-section.background-fixed .background-image-wrapper,
.mobile .fullscreen-section .background-image{
  width: 100vw;
  position: relative !important;
}

/* Bullet Nav */
.fs-pagination {
  width: 2.8rem;
  padding: 1rem;
  /*background: rgba(0,0,0,0.2);*/
  text-align: center;
  visibility: visible;
  opacity: 0;
  position: fixed;
  right: 25px;
  top: 50%;
  z-index: 10;
  -webkit-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  -webkit-transition-duration: 600ms;
  transition-duration: 600ms;
  -webkit-backface-visibility: hidden;
}
.fs-bullet-nav {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  background: $white;
  opacity: 0.5;
  border-radius: 50%;
}
.nav-dark .fs-bullet-nav{
  background: #333;
}
.fullscreen-sections-wrapper.nav-dark .fs-bullet-nav{
  background: #333;
}
.fs-bullet-nav:hover {
  opacity: 1;
}
.fs-bullet-nav.active {
  opacity: 1;
}

/* Mobile */
.mobile .fs-pagination{
  display: none;
}

/* Fullscreen Content */
.fullscreen-section .fullscreen-inner{
  width: 100%;
  height: 100%;
  padding-top: 7rem;
  padding-bottom: 7rem;
  /* background: rgba( 0, 0, 0, 0.6); */
  display: table-cell;
  vertical-align: middle;
  position: relative;
  z-index: 2;
}
.fullscreen-section.no-overlay .fullscreen-inner{
  background: rgba( 0, 0, 0, 0);
}
.fullscreen-section footer{
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: absolute;
  bottom: 0;
  z-index: 3;
}
.fullscreen-section .copyright{
  margin-bottom: 0;
  font-size: 1.1rem;
  font-weight: normal;
  letter-spacing: 0.1rem;
  color: $white;
}
.fullscreen-section .social-list{
  margin-bottom: 0;
}
.fullscreen-inner.v-align-top{
  vertical-align: top;
}
.fullscreen-inner.v-align-bottom{
  vertical-align: bottom;
}

/* Hero */
.fullscreen-section .hero-4,
.fullscreen-section .hero-5,
.fullscreen-section .hero-6{
  height: 100vh;
}


/* Coming soon */
.coming-soon-1 .background-image{
  background-image:url(../images/fullscreen/coming-soon-1.jpg);
}
.coming-soon-2 .background-image{
  background-image:url(../images/fullscreen/coming-soon-1.jpg);
}
.coming-soon-2 h1{
  font-size: 10rem;
  line-height: 1;
}

/* 404 */
.error-404 .background-image{
  background-image:url(../images/fullscreen/coming-soon-1.jpg);
}
.error-404 h1{
  font-size: 15rem;
  line-height: 1;
}

/* 500 */
.error-500 .background-image{
  background-image:url(../images/fullscreen/coming-soon-1.jpg);
}
.error-500 h1{
  font-size: 15rem;
  line-height: 1;
}
.error-500 .button{
  margin-right: 2rem;
}
.error-500 .button:last-child{
  margin-right: 0;
}
[class*="error-"] .background-image,
[class*="coming-soon-"] .background-image{
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}

/* Fullscreen Media Queries */
@media only screen and (max-width: 768px) {
  .fs-pagination{
    display: none;
  }
  .fullscreen-section footer{
    position: relative;
  }

  /* Clear margin for last child in last column */
  .fullscreen-section .column:last-child > *:last-child:not(.hero-content):not(input[type="submit"]){
    margin-bottom: 0;
  }

  /* Change display for content that appears below image */
  .fullscreen-section.content-below-on-mobile{
    height: auto;
    display: block;
  }
  .fullscreen-section.content-below-on-mobile .fullscreen-inner{
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
    background-color: transparent;
    display: block;
    text-align: left;
  }
  .fullscreen-section.no-overlay.content-below-on-mobile .background-on-mobile{
    padding: 0;
    margin-bottom: 0;
    background-color: #f4f4f4;
  }

  /* Default text color on mobile when positioned below image or
  when there is a background content wrapper on mobile */
  .fullscreen-section.no-overlay.content-below-on-mobile *,
  .fullscreen-section .background-on-mobile *{
    color: $grey-60;
  }
  .fullscreen-section.content-below-on-mobile .alt-color-on-mobile{
    color: $white;
  }

  /* Change position and bkg attachment so image scrolls with content */
  .fullscreen-section .background-image-wrapper,
  .webkit .fullscreen-section .background-image-wrapper,
  .fullscreen-section.background-fixed .background-image-wrapper,
  .webkit .fullscreen-section.background-fixed .background-image-wrapper,
  .fullscreen-section .background-image,
  .fullscreen-section.background-fixed .background-image,
  .webkit .fullscreen-section.background-fixed .background-image{
    max-width: 100vw;
    background-attachment: scroll;
  }
  .fs-image-scale .background-image-wrapper,
  .fs-image-scale .background-image,
  .content-below-on-mobile .background-image-wrapper,
  .content-below-on-mobile .background-image,
  .content-below-on-mobile .background-slider-wrapper{
    position: relative !important;
  }
  .fullscreen-section.background-contain .background-image,
  .fullscreen-section.background-contain .background-image{
    background-position: center !important;
    background-size: 100% !important;
  }
  .mobile .fullscreen-section .background-image{
    max-width: 114rem;
    height: inherit;
  }
  .mobile .fullscreen-section{
    margin-top: -1px;
  }
  .coming-soon-2 h1{
    font-size: 6rem;
  }
  .error-404 .form-submit{
    width: auto;
  }
  .error-404 h1,
  .error-500 h1{
    font-size: 10rem;
  }
}
.mobile .fullscreen-section[class*="error-"],
.mobile .fullscreen-section[class*="coming-soon-"]{
  height: 100vh;
  display: table;
}
.mobile [class*="error-"] .fullscreen-inner,
.mobile [class*="coming-soon-"] .fullscreen-inner{
  display: table-cell;
}
.mobile .fullscreen-section[class*="error-"] .background-image-wrapper,
.mobile .fullscreen-section[class*="coming-soon-"] .background-image-wrapper{
  position: fixed;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (		 min-device-pixel-ratio: 2),
only screen and (			min-resolution: 192dpi),
only screen and (				min-resolution: 2dppx) {
  .coming-soon-1 .background-image{
    background-image: url(../images/fullscreen/coming-soon-1@2x.jpg);
  }
  .coming-soon-2 .background-image{
    background-image: url(../images/fullscreen/coming-soon-1@2x.jpg);
  }
  .error-404 .background-image{
    background-image: url(../images/fullscreen/coming-soon-1@2x.jpg);
  }
  .error-500 .background-image{
    background-image: url(../images/fullscreen/coming-soon-1@2x.jpg);
  }
}