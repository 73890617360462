/* Header */
.side-navigation-header {
  min-height: 8rem;
  line-height: 8rem;
}
.side-navigation-header {
  font-size: 1.4rem;
  line-height: 1.8;
}
.side-navigation-header{
  padding: 0 4rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #232323;
}

/* Close */
.side-navigation-wrapper .navigation-hide{
  position: absolute;
  right: 0;
  top: 0;
}

/* Navigation */
.side-navigation{
  margin-bottom: 3rem;
}
.side-navigation-inner > *:nth-last-child(2){
  padding-bottom: 8rem;
  margin-bottom: 0;
}




/* SKIN */
.header .navigation-show {

  * {
    padding: 0;
    margin: 0;
  }

  a {
    font-size: 2.4rem;
    text-align: center;
  }

  &.nav-icon {
    width: auto;
    height: auto;

    font-size: 2rem;
  }

}


/* Transparent Header Initial & Hover Colors */
.header-transparent .navigation-show a,
.header-transparent .navigation-show a:hover {
  color: $white;
}

/* Aux Navigation Hide */
.navigation-hide {
  width: 4.4rem;
  height: 4.4rem;
  margin-top: 1.9rem;
  margin-right: 0;
}

.navigation-hide a {
  background: none;
}

.navigation-hide a span {
  width: 4.4rem;
  font-size: 2rem;
}

/* Side Navigation Background Color */
.side-navigation-wrapper .logo {
  width: 12rem;
}

.side-navigation-wrapper {
  background-color: #111;
  box-shadow: none !important;
}

.side-navigation-wrapper.slide-in-reset {
  background-color: rgba(0, 0, 0, 0.7);
}

.side-navigation-header {
  border: none;
}

/* Side Main Nav Initial Colors & Padding */
.side-navigation > ul > li > a {
  padding: 0.8rem 4rem;

  color: $grey-20;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  position: relative;
}

.side-navigation ul li.current > a {
  color: $white;
}

.side-navigation > ul > li > a:after {
  width: 0;
  height: 0.1rem;
  margin-left: 0;
  content: "";
  background-color: $white;
  opacity: 0;
  position: absolute;
  left: 0;
  bottom: 1rem;
  transition-property: width, opacity;
  transition-duration: 500ms;
}

.side-navigation-wrapper .navigation-hide {
  margin-right: 2rem;
  color: $grey-60;
}

.side-navigation-wrapper .navigation-hide a:hover {
  color: $white;
}


/* Side Nav Hover Colors */
.side-navigation ul li a:hover,
.side-navigation ul li.current a:hover {
  color: $white;
}


.side-navigation-footer .copyright {
  font-size: 1.1rem;
}