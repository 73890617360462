.social-1{
  text-align: center;
}
.social-1 h6{
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.social-1 ul{
  margin-bottom: 0;
  list-style: none;
}
.social-1 ul li{
  padding: 0 1rem;
  margin-bottom: 1rem;
  display: inline-block;
}

/* Social 2 */
.social-2{
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
}
.safari-browser .social-2{
  width: 100.4%;
}
.social-2 [class*="content-grid-"]{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.social-2.full-width [class*="content-grid-"]{
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.social-2 .grid-item{
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0;
}
.social-2 a{
  width: 100%;
  height: 15rem;
  margin: 0;
  font-size: 2rem;
  line-height: 2rem;
  font-family: "Times New Roman", serif;
  font-style: italic;
  display: table;
}
.social-2 a:hover{
  background: none;
  color: #999;
}
.social-2 a span{
  display: table-cell;
  vertical-align: middle;
}
.social-2 [class*="icon"]{
  width: 100%;
  margin: 0 0 1rem 0;
  font-size: 3rem;
  display: inline-block;
}

/* Social 3 */
.social-3{
  text-align: center;
  background-image:url(../images/slider/slide-2-fs-hp@2x.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
}
.social-3 ul{
  margin-bottom: -1rem;
  list-style: none;
  line-height: 1;
}
.social-3 ul li{
  padding: 0 1rem;
  margin-bottom: 1rem;
  display: inline-block;
  text-align: center;
}
.social-3 a[class*="icon"]{
  width: 5rem;
  height: 5rem;
  margin: 0;
  line-height: 5rem;
  font-weight: normal;
  background: rgba(255,255,255,0.2);
  border-radius: 50%;
}
.social-3 a{
  color: $white;
}
.social-3 a:hover{
  background: $white;
  /*color: #333;*/
}

/* Social 4 */
.social-4{
  text-align: center;
}
.social-4 h6{
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.social-4 ul{
  margin-bottom: -1rem;
  list-style: none;
}
.social-4 ul li{
  padding: 0 1rem;
  margin: 0 2rem 1rem 2rem;
  display: inline-block;
}
.social-4 ul li a{
  font-size: 1.7rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: bold;
  color: #999;
}
.social-4 ul li a:hover{
  /*color: $grey-60;*/
}

/* Social Media Queries */
@media only screen and (max-width: 480px) {
  .social-2 ul li{
    width: 50%;
  }
  .social-2 a{
    width: 100%;
  }
  .social-4 ul li{
    display: block;
  }
}
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (		 min-device-pixel-ratio: 2),
only screen and (			min-resolution: 192dpi),
only screen and (				min-resolution: 2dppx) {
  .social-3{
    background-image:url(../images/slider/slide-2-fs-hp@2x.jpg);
  }
}