[class*="intro-title-"] .row,
[class*="intro-title-"] .column,
.title-container{
  height: 100%;
}
.title-container[class^="title-"]{
  margin-bottom: 0;
}
.title-container{
  width: 100%;
  display: table;
}
.title-container-inner{
  display: table-cell;
  vertical-align: middle;
}
.title-container-inner > *:first-child{
  margin-top: 0;
}
.title-container-inner > *:last-child{
  margin-bottom: 0;
}
