@import "backgrounds";
@import "opacity";
@import "masonry";

@import "header";
@import "side-nav";

@import "title-sections";
@import "hero-sections";
@import "feature-sections";
@import "call-to-actions";
@import "forms";
@import "testimonials";
//@import "team-sections";
@import "portfolio";
//@import "logo-sections";
//@import "client-sections";
@import "social";
//@import "stats";
@import "footers";
//@import "maps";
@import "pagination";
@import "full-screen";
@import "blog";
@import "single-post";

@import "margins";
@import "padding";
@import "push-pull";

/* Background classes */

.horizon{
	opacity: 0;
	visibility: hidden;
}

/* Nav Onepage */
.aux-navigation-active .one-page-nav + .wrapper{
	position: absolute;
}
.aux-navigation-active .side-navigation-wrapper.one-page-nav,
.aux-navigation-active .overlay-navigation-wrapper.one-page-nav{
	position: fixed;
}

/* Swap Position */
.wrapper.inactive .header-fixed,
.wrapper.inactive .tm-slider-parallax-container .tm-slider-container{
	position: relative !important;
}
.wrapper.inactive .header-fixed-on-mobile .header-inner{
	position: absolute !important
}

/* Media Overlay */
.media-overlay{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}
.media-overlay + *{
	position: relative;
}

