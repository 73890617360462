[class*="call-to-action-"] .row,
[class*="call-to-action-"] .column{
  height: 100%;
}
.call-to-action-content{
  width: 100%;
  height: 100%;
  display: table !important;
}
.call-to-action-content-inner{
  display: table-cell;
  vertical-align: middle;
}
[class*="call-to-action-"] .column:last-child .call-to-action-content-inner{
  padding-bottom: 0;
}
.call-to-action-content-inner *:first-child{
  margin-top: 0;
}
.call-to-action-content-inner *:last-child{
  margin-bottom: 0;
}
/*[class*="call-to-action-"]{
  background-color: inherit;
}*/

/* Call to Action 1 */
.call-to-action-1{
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
}
.call-to-action-1{
  width: 100%;
  display: block;
}
.call-to-action-1:hover{
  background-color: #1ecd6d;
}
.call-to-action-1 span{
  display: block;
}

/* Call to Action 2 */
.call-to-action-2{
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
}
.call-to-action-2 p{
  font-size: 2.5rem;
}
@media only screen and (min-width: 768px){
  .call-to-action-2 .inline{
    display: block;
  }
  .call-to-action-2 p + .button{
    margin-left: 2rem;
  }
}

/* Call to Action 3 */
.call-to-action-3{
  background-color: #ffbe12;
  color: $white;
  position: relative;
}
.call-to-action-3 p{
  font-size: 2.5rem;
}
.call-to-action-3 .call-to-action-button{
  padding-right: 0;
  position: absolute;
  top: 0;
  right: 0;
}
.call-to-action-3 .call-to-action-button .button{
  padding: 2rem 0;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0;
  display: table;
}
.call-to-action-3 .call-to-action-button > .button > span{
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

/* Call to Action Media Queries */
@media only screen and (max-width: 960px){
  .call-to-action-1,
  .call-to-action-2 p,
  .call-to-action-3 p{
    font-size: 1.9rem;
    line-height: 2.9rem;
  }
}
@media only screen and (max-width: 768px){
  [class*="call-to-action"]{
    height: auto;
  }
  .call-to-action-2 .call-to-action-content-inner,
  .call-to-action-3 .call-to-action-content-inner{
    padding-bottom: 2.5rem;
    text-align: center;
  }
  .call-to-action-3 .row{
    max-width: 100%;
  }
  .call-to-action-3 .call-to-action-button{
    position: static;
    padding-left: 0;
    padding-right: 0;
  }
}
