.feature-content,
.feature-image,
.feature-slider{
  width: 100%;
  height: 100%;
  display: table;
  table-layout: fixed;
}
.feature-content-inner,
.feature-image-inner,
.feature-slider-inner{
  display: table-cell;
  vertical-align: middle;
}
.feature-slider .tm-slider-container{
  width: 100% !important;
}
.feature-slider .tms-pagination{
  bottom: 0;
}
.feature-content-inner *:first-child{
  margin-top: 0;
}

/* Feature 1 */
.section-block.feature-1{
  padding-bottom: 0;
}
.feature-1{
  text-align: center;
}

/* Feature 2 */
.section-block.feature-2{
  padding-bottom: 0;
}
.feature-2{
  position: relative;
}
.feature-2 .row .feature-image,
.feature-2 .row.flex .feature-image{
  width: 170%;
  margin-right: -70%
}
.feature-2.right .row .feature-image{
  margin-left: -70%
}

/* Feature 3 */
.feature-3{
  position: relative;
}
.feature-3 .feature-image{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.feature-3 .feature-image-inner{
  max-height: 53rem;
}

/* Feature Media Queries */
@media only screen and (max-width: 960px){
  .feature-2{
    padding-bottom: 7rem;
  }
  .feature-2 .row .feature-image{
    height: 100%;
  }
  .header + .content > [class*="feature-"]:first-child{
    padding-top: 5rem;
  }
}
@media only screen and (min-width: 768px){
  .feature-2 > .row > .column,
  .feature-2 > .row > .column > .box,
  .feature-3 > .row > .column,
  .feature-3 > .row > .column > .box{
    height: auto;
    display: inherit;
  }
  .feature-2 > .row > .column > *:not(.box),
  .feature-2 > .row >.column > div > *:not(.box),
  .feature-3 > .row >.column > *:not(.box),
  .feature-3 > .row >.column > div > *:not(.box){
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .feature-2 .feature-content-inner *:last-child{
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 768px){
  .feature-2 .column,
  .feature-3 .column{
    height: auto !important;
  }
  .feature-2.left{
    padding-bottom: 0;
  }
  .feature-2 .row .feature-image{
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    float: none;
  }
  .feature-2.right .column + .column .feature-content{
    margin-bottom: 0;
  }
  .feature-3 .feature-image{
    text-align: center;
  }
  .feature-3 .feature-image img,
  .feature-3 .feature-slider{
    max-width: 60%;
    margin: 0 auto;
  }
  .feature-2.right .feature-image,
  .feature-3 .feature-image,
  .feature-3 .feature-slider{
    margin-bottom: 5rem;
  }
  .feature-3 .column:last-child .feature-image{
    margin-bottom: 0;
  }
  .feature-3 .column:last-child .feature-content{
    padding-bottom: 0;
  }
  .feature-3 .column:last-child .feature-column:last-child{
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 480px){
  .feature-3 .feature-image img,
  .feature-3 .feature-slider{
    max-width: 70%;
  }
}
