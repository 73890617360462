.header.header-animated {
  transition-property: height, transform, visibility;
}

.header-animated .header-inner,
.header-animated .header-inner .navigation,
.header-animated .header-inner .navigation > ul > li,
.header-animated .header-inner .navigation > ul > li > a,
.header-animated .navigation-show a {
  transition-property: height, background, border, padding, margin, font-size, color, line-height, opacity;
}

.header-animated .logo {
  transition-property: width, height, background, border, padding, margin, font-size, line-height, opacity;
}

.header-animated,
.header-animated .header-inner,
.header-animated .header-inner .navigation,
.header-animated .header-inner .navigation > ul > li,
.header-animated .header-inner .navigation > ul > li > a,
.header-animated .navigation-show a,
.header-animated .logo,
.header-animated .logo a {
  transition-duration: 500ms;
}