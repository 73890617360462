[class*="hero-"]{
  position: relative;
}
[class*="hero-"] > .row:not(.flex),
[class*="hero-"] > .row:not(.flex) > .column{
  height: 100%;
}
.hero-content:not([class*="width-"]){
  width: 100%;
}
.hero-content,
.hero-slider .tms-caption.no-transition{
  height: 100%;
  display: table !important;
  table-layout: fixed;
}
.section-block .hero-content-inner,
.hero-slider .hero-content-inner{
  display: table-cell;
  vertical-align: middle;
}
.section-block .hero-content-inner .row,
.section-block .hero-content-inner .column{
  height: auto;
}
.hero-content-inner > *:first-child{
  margin-top: 0;
}
.hero-content-inner > *:last-child{
  margin-bottom: 0;
}

/* Hero 1 */
.hero-1{
  height: 50rem;
  text-align: left;
  color: $white;
}
.hero-1 h2,
.hero-1 h6{
  color: $white;
}

/* Hero 2 */
.hero-2{
  height: 50rem;
  text-align: left;
  color: $white;
  background-repeat: no-repeat;
}
.hero-2 h2,
.hero-2 h6{
  color: $white;
}

/* Hero 3 */
.hero-3{
  height: 50rem;
  text-align: left;
  display: table;
  background-repeat: no-repeat;
}
.hero-3 > div{
  display: table-cell;
  vertical-align: middle;
  float: none;
}
.hero-3 > div > .row,
.hero-3 > div > .row > .column{
  height: auto;
}
.hero-3.right > div > .row > .column{
  float: right;
}
.hero-3.top{
  padding-top: 0;
}
.hero-3.top > div{
  vertical-align: top;
}
.hero-3.top .hero-content{
  border-bottom: 8px solid #333;
}
.hero-3.bottom{
  padding-bottom: 0;
}
.hero-3.bottom > div{
  vertical-align: bottom;
}
.hero-3.bottom .hero-content{
  border-top: 8px solid #333;
}
.hero-3.center:not(.middle){
  padding-top: 0;
}
.hero-3.center-left > div > .row > .column{
  text-align: left;
}
.hero-3.center > div > .row > .column{
  margin: auto;
  float: none;
}
.hero-3.center-right > div > .row > .column{
  text-align: right;
  float: right;
}
.hero-3 > div > .row{
  width: 100%;
  bottom: 0;
}
.hero-3 .hero-content{
  background: $white;
  position: relative;
  bottom: 0;
}
.hero-3 .hero-content-inner{
  padding: 3rem;
}
.hero-3 h2,
.hero-3 h6{
  color: $grey-60;
}

/* Hero 4 */
.hero-4{
  height: 50rem;
  text-align: left;
  color: $white;
  background-repeat: no-repeat;
}
.hero-4 > .row:before{
  width: 50%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
.hero-4.right > .row:before{
  top: 0;
  right: 0;
  left: auto;
}
.hero-4 .column{
  position: relative;
}
.hero-4 h2,
.hero-4 h6{
  color: $white;
}

/* Hero 5 */
.hero-5 > .row > .column{
  position: relative;
  z-index: 1;
}
.hero-5 .media-column{
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
  position:absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.hero-5 .media-column iframe,
.hero-5 .media-column video,
.hero-5 .media-column .map-container{
  width: 100%;
  height: 100%;
}
.hero-5 .media-column .hero-content-inner{
  padding: 8.5rem;
}
.hero-5.right > .media-column,
.hero-5 .media-column + .media-column{
  left: auto;
  right: 0;
}
.hero-5 .media-column .content-slider{
  height: 100% !important;
}

/* Hero Slider */
.hero-slider{
  width: 100% !important;
  height: auto;
  min-height: 50rem;
  margin-bottom: 0;
}
.hero-slider .tms-slides,
.hero-slider .tms-slide,
.hero-slider .hero-content{
  height: 100%;
}
.hero-slider .tms-slides{
  display: flex;
  flex-wrap: wrap;
}
.hero-slider .tms-slide{
  padding: 0 !important;
  display: inherit;
}
.hero-slider .tms-slide > .media-column{
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
  position:absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.hero-slider .tms-slide.right > .media-column{
  left: auto;
  right: 0;
}
.hero-slider .tms-pagination{
  width: 50%;
  text-align: right;
  padding-right: 5rem;
}
.hero-slider .hero-content:not([class*="width-"]){
  width: 50%;
}
.hero-slider .hero-content{
  min-height: 50rem;
  background-color:rgba(0,0,0,0.6);
  position: absolute;
  top: 0;
  left: 0;
}
.hero-slider .media-column + .row .hero-content{
  background: none;
}
.hero-slider .hero-content *:last-child{
  margin-bottom: 0;
}
.hero-slider .tms-content-scalable{
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.hero-slider .tms-slide[class*="bkg-"] .hero-content{
  background-color: inherit;
}
.hero-slider .hero-content.right{
  text-align: left;
  left: auto;
  right: 0;
}

/* Hero Media Queries */
@media only screen and (max-width: 1040px){
  .hero-5 .media-column .hero-content-inner{
    padding: 2.5rem;
  }
}
@media only screen and (max-width: 768px){
  .hero-1,
  .hero-2,
  .hero-3,
  .hero-4,
  .hero-5{
    height: auto !important;
  }
  [class*="hero-"] .column:first-child .hero-content{
    margin-bottom: 5rem;
  }
  [class*="hero-"] .column:last-child .hero-content{
    margin-bottom: 0;
  }
  .hero-content{
    text-align: center;
  }
  .hero-3[class*="center"]{
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .hero-4 > .row:before,
  .hero-4 > .row:after{
    width: 100%;
    left: 0;
    top: 0;
  }
  .hero-5 .media-column{
    display: none;
    visibility: hidden;
  }
  .hero-5.show-media-column-on-mobile{
    padding-top: 0;
  }
  .hero-5.show-media-column-on-mobile .media-column{
    display: block;
    visibility: visible;
  }
  .hero-5.show-media-column-on-mobile .media-column{
    width: 100%;
    position: relative;
  }
  .hero-5.show-media-column-on-mobile .media-column,
  .hero-5.show-media-column-on-mobile .media-column iframe,
  .hero-5.show-media-column-on-mobile .media-column video{
    height: 40rem;
  }
  .hero-5 .media-column .split-hero-content{
    max-width: 60rem;
    margin:  0 auto;
  }
  .hero-5 .media-column .hero-content-inner{
    padding: 1.5rem;
  }
  .hero-5 .column:first-child:not(.media-column) .split-hero-content{
    margin-top: 11rem;
  }
  .hero-5 .column:not(.media-column) .split-hero-content{
    margin-top: 5rem;
    margin-bottom: 0;
  }
  .hero-5 .row + .media-column{
    margin-top: 8rem;
    margin-bottom: -11rem;
  }
  .hero-5 .tms-pagination{
    text-align: center !important;
  }
  .hero-slider .tms-pagination{
    width: 100%;
    padding: 0;
    text-align: center;
  }
  .hero-slider .hero-content-inner{
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .hero-slider .hero-content{
    width: 100%;
    height: inherit;
    text-align: center;
    background-color:rgba(0,0,0,0.6);
  }
  .hero-slider .hero-content,
  .hero-slider .hero-content *{
    color: $white;
  }
  .hero-slider  .media-column + .row{
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 600px) {
  [class*="hero-"] > .row > .column,
  .hero-5 .media-column .split-hero-content{
    max-width: 48rem;
  }
}
@media only screen and (max-width: 480px){
  [class*="hero-"] > .row > .column,
  .hero-5 .media-column .split-hero-content{
    max-width: 35rem;
  }
}
@media only screen and (max-width: 350px){
  [class*="hero-"] > .row > .column,
  .hero-5 .media-column .split-hero-content{
    width: 100%;
  }
}
