/*------------------------------------------------------------------
Warhol Base Skin
Version: 1.0.1;
Author: ThemeMountain
Copyright: ThemeMountain

[Table of contents]

1. Typography
2. Background Color & Color Classes
3. Section Padding
4. Header Styling
5. Header Logo
6. Header Navigation
7. Header Buttons, Dropdowns & Icons
8. Auxiliary Navigation
9. Title Areas
10. Slider & Parallax
11. Lightbox
12. Preloaders
13. Rollovers
14. Homepage
15. About Pages
16. Contact Pages
17. Project Pages
18. Pagination
19. Fullscreen Pages
20. Blog Pages
21. Form Elements
22. Social Lists
23. Footer
24. Sidebar
25. Dividers
26. Media Element Player
27. E-Commerce
28. Component Styling
29. Resolution Media Queries

-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[15. About Pages]
*/


/*------------------------------------------------------------------
[18. Pagination]
*/

/* Pagination 3 Customized */
.pagination-3 a {
  width: 4rem;
  height: 4rem;
  padding: 0;
  line-height: 4rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: $black;
  border-radius: 50%;
}

.pagination-3 .pagination-previous,
.pagination-3 .pagination-next,
.pagination-3 a:hover {
  background: #eee;
  border: 1px solid #eee;
  color: $black;
}

.pagination-3 a.current {
  background: $brand;
  border: 1px solid $brand;
  font-weight: 500;
  color: $white;
}

.pagination-3 a.disabled,
.pagination-3 a.disabled:hover {
  border-color: #eee;
}

.pagination-3 a span {
  margin: 0;
}

/*------------------------------------------------------------------
[19. Fullscreen Pages]
*/

.fs-bullet-nav {
  background: none;
  border: 1px solid $white;
}

.fs-bullet-nav.active {
  background: $white;
}

.fs-pagination {
  right: 6rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5rem;
}

/*------------------------------------------------------------------
[20. Blog Pages]
*/

/* Post Title */
.blog-regular h2.post-title,
.blog-wide h2.post-title,
.blog-single-post h2.post-title {
  margin-bottom: 1rem;
}

.blog-masonry h2.post-title {
  margin-bottom: 0;
}

.blog-masonry .post-title {
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.8rem;
  text-transform: none;
  display: block;
}

.post-title {
  margin-bottom: 0.5rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
}

.post-title a {
  color: $black;
}

.box .post-title a:hover {
  opacity: 0.5;
}

.single-post-tags .tags-title {
  font-size: 1.3rem;
}

.blog-masonry.masonry-set-dimensions .post-title {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1;
}

.blog-masonry.masonry-set-dimensions .post-title.quote {
  line-height: inherit;
}

.blog-masonry.masonry-set-dimensions .post-title a {
  color: $white;
}

.masonry-set-dimensions .content-inner.with-overlay {
  background-color: rgba(0, 0, 0, 0.3)
}

/* Common */
.post-content.with-background,
.home-page .post-content.with-background {
  background-color: $white;
}

.post-info {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  display: block;
  font-size: 1.1rem;
  line-height: 1.2;
  font-weight: 500;
  color: #999;
}

.overlay-info .post-info {
  margin-bottom: 0;
  color: $white;
}

.post-info.boxed {
  width: 100%;
  padding: 2rem 0;
  margin-bottom: 7rem;
  background-color: #fafafa;
}

.post-info span {
  margin-left: 1rem;
  margin-right: 1rem;
}

.post-info span,
.post-info a {
  display: inline-block;
}



.post-content .read-more {
  font-size: 1.3rem;
}

.post-content .read-more [class*="icon-"] {
  margin: 0;
}

.box > a.read-more:not(.button) {
  font-weight: 400;
  text-align: left;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .blog-regular .post-title,
  .blog-wide .post-title {
    font-size: 3rem;
  }
}

/* Regular Blog */
.blog-regular .post {
  margin-bottom: 5rem;
}

.blog-masonry .caption-over-outer .post-info .post-date {
  padding: 0;
  margin: 0;
  border: none;
  display: inline-block;
}

/* Blog Masonry */
.blog-masonry .post-content:not(.post-content.with-background):not(.box) {
  padding: 0 2rem;
}

/* Blog Masonry Set Dimentions */
.blog-masonry.masonry-set-dimensions .post-info,
.blog-masonry.masonry-set-dimensions .post-info a {
  color: $white;
}

.post-media .mejs-container.mejs-audio .mejs-controls {
  border-radius: 0;
}


.masonry-set-dimensions .post-media:hover .post-content {
  background: rgba(0, 0, 0, 0.8);
}

.masonry-set-dimensions .post-media:hover .post-content h2 a {
  color: #fdeb74;
}


/*------------------------------------------------------------------
[21. Form Elements]
*/

.field-wrapper {
  position: relative;
  overflow: hidden;
}

.field-wrapper label:not(.checkbox-label):not(.radio-label) {
  opacity: 0.5;
}

.form-submit.full-width {
  width: 100%;
}

/* Common Styling */
.contact-form textarea {
  min-height: 15rem;
}

[class*="form-container"] .column {
  position: relative;
}

.form-response {
  position: absolute;
  font-size: 1rem;
}

.form-element,
textarea {
  margin-bottom: 3rem;
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-color: #ddd;
}

.form-element,
textarea,
select {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 1.3rem;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0.1rem;
  color: $grey-60;
}

.form-select:after {
  font-weight: 500;
  right: 0 !important;
}

.form-element:focus,
textarea:focus {
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-color: $brand;
  box-shadow: none;
  color: $black;
}

.form-element.required-field:focus,
textarea.required-field:focus {
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-color: #ddd;
  box-shadow: none;
  color: $black;
}

.form-element.required-field,
textarea.required-field {
  background-color: transparent;
  border-color: $brand;
  box-shadow: none;
  color: $black;
  position: relative;
}

.form-element::-webkit-input-placeholder {
  color: $grey-60;
}

.form-element:focus::-webkit-input-placeholder {
  color: $black;
}

.form-element::-moz-placeholder {
  color: $grey-60;
}

.form-element:focus::-moz-placeholder {
  color: $black;
}

.form-element:-ms-input-placeholder {
  color: $grey-60;
}

.form-element:focus:-ms-input-placeholder {
  color: $black;
}

/* Checkbox and Radio */
.checkbox-label,
.radio-label {
  color: $grey-60;
  position: relative;
}

.checkbox + .checkbox-label:before,
.radio + .radio-label:before {
  background: transparent;
  border-color: #ddd;
}

.checkbox:checked + .checkbox-label,
.radio:checked + .radio-label {
  opacity: 1;
}

.checkbox:checked + .checkbox-label:before {
  content: '\e63a';
  background: $brand;
  border-color: $brand;
  color: $white;
}

.radio:checked + .radio-label:before {
  background: $brand;
}

.checkbox.required-field {
  position: absolute;
}

.checkbox.required-field + .checkbox-label:before {
  border-color: $brand;
}



/* Disabled */
.disabled {
  opacity: 0.6;
  background-color: transparent !important;
  border-color: #eee !important;
  color: #999 !important;
}

.disabled:hover {
  opacity: 0.6;
  background-color: transparent;
  border-color: #eee;
}

/*------------------------------------------------------------------
[22. Social Lists]
*/

.social-list li {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
}

.social-list li a {
  color: #333;
}

.social-list li.social-list-label {
  color: #333;
}

/*------------------------------------------------------------------
[25. Dividers]
*/

hr,
.divider {
  border-color: #eee;
}

.text-line {
  width: 0;
  transform: rotate(0deg);
  transition-property: width, opacity, background-color, transform;
  transition-duration: 400ms;
}

a.animated-link:hover .text-line,
a.overlay-link:hover .text-line {
  width: 2rem;
  transform: rotate(0deg);
}

.mobile .text-line,
a.disabled .text-line {
  transition-property: none;
}

[class*="border-"].thick,
[class*="border-"].thick * {
  border-width: 0.2rem;
}
\

/*------------------------------------------------------------------
[27. Component Styling]
*/

/* Button */
.button {
  text-transform: none;
}

.button-content {
  line-height: 1;
}


/* Scroll Links */
.icon-circled.medium {
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
}

.icon-circled.small {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
}

/* Box */
.box {
  font-size: inherit !important;
  line-height: inherit !important;
}

/* Blockquote */
blockquote.border {
  border-color: #ccc;
}

blockquote cite {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #333;
  font-style: normal;
  opacity: 1;
}

/* Feature Section */
.feature-2 .feature-image img {
  width: 90%;
}

/* Logo Section */
.logos-1 a {
  opacity: 1;
}

.logos-1 a:hover {
  opacity: 1;
}

.logo-slider {
  height: 14rem;
}

.logos-1 a,
.logos-1 span {
  padding: 2rem 0;
}

/* Clients Section */
.clients-1 .client-name {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.4rem;
  opacity: 0.5;
}

.clients-1 .counter {
  font-size: 10rem;
  margin-bottom: 0;
}

/* Profile Image */
.profile-image.pull-left {
  margin-right: 3.5rem;
  margin-bottom: 2rem;
}

.profile-image.pull-right {
  margin-left: 3.5rem;
  margin-bottom: 2rem;
}

/* Hero Sections */
.hero-1,
.hero-2,
.hero-3,
.hero-4,
.hero-5,
.hero-6 {
  height: 50rem;
}

.home-page .hero-1 {
  padding-top: 12rem;
}

[class*="hero-"].small {
  height: 30rem;
}

[class*="hero-"].window-height {
  height: 100vh;
}

.hero-3 .hero-content {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-color: $brand !important;
}

.hero-5 .center .tms-pagination {
  text-align: center;
}

/* Stats */
[class*="stats-"] .counter {
  margin-bottom: 1rem;
  font-size: 4.5rem;
  font-weight: 300;
}

[class*="stats-"] .counter.xlarge {
  font-size: 6.5rem;
}

[class*="stats-"] .description {
  font-size: 1.2rem;
  text-transform: none;
  font-weight: 400;
  color: $black;
}

.stats-3 .mega-stat .counter {
  font-weight: normal;
  font-size: 6rem;
}

.stats-3 .description {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.stats-3 .description,
.stats-3 .mega-stat .description {
  font-size: 1.2rem;
  font-weight: 400;
}

.maintenance-stat .counter {
  font-size: 6.5rem;
  font-weight: 300;
  line-height: 1;
}

/* Bar percentages */
.percent-10 {
  width: 10%;
}

.percent-20 {
  width: 20%;
}

.percent-30 {
  width: 30%;
}

.percent-40 {
  width: 40%;
}

.percent-50 {
  width: 50%;
}

.percent-60 {
  width: 60%;
}

.percent-70 {
  width: 70%;
}

.percent-80 {
  width: 80%;
}

.percent-90 {
  width: 90%;
}

.percent-10 {
  width: 100%;
}

/* Sizes */
.progress-bar {
  border: none;
}

.progress-bar .bar {
  padding: 0 0.5rem;
  font-weight: 400;
}

.progress-bar.small {
  height: 0.4rem;
}

.progress-bar,
.progress-bar.medium {
  height: 0.8rem;
}

.progress-bar.large {
  height: 1.3rem;
  line-height: 1rem;
  font-size: 1rem;
}

.progress-bar.xlarge {
  height: 1.6rem;
  line-height: 1.4rem;
  font-size: 1.2rem;
}

/* Accordion */
.accordion > ul > li > a {
  padding: 1.3rem 2.5rem;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  color: $grey-60;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
}

.accordion > ul > li.active > a,
.accordion > ul > li.active > a:hover {
  border-color: transparent;
  background-color: #232323;
  color: $white;
}

.accordion > ul > li > a:hover {
  border-color: transparent;
  background-color: #ddd;
}

.accordion .accordion-content {
  padding: 3rem;
}

/* style 1 */
.accordion.style-1 > ul > li > a {
  background-color: transparent;
  border-color: #eee;
  color: $grey-60;
}

.accordion.style-1 > ul > li.active > a,
.accordion.style-1 > ul > li.active > a:hover {
  background-color: transparent;
  border-color: #eee;
  color: #232323;
}

.accordion.style-1 > ul > li > div {
  border: none;
}

.accordion.style-1 .accordion-content {
  border: none
}

/* style 2 */
.accordion.style-2 > ul > li > a {
  padding: 1.3rem 0;
  border: none;
  font-weight: 400;
  background-color: transparent;
  border-bottom: 1px solid #eee;
  color: $grey-60;
}

.accordion.style-2 > ul > li.active > a,
.accordion.style-2 > ul > li.active > a:hover {
  background-color: transparent;
  border-color: transparent;
  color: $brand;
}

.accordion.style-2 .accordion-content {
  padding: 1rem 0 3rem 0;
  border: none;
}

.accordion.style-2 > ul > li > div {
  border: none;
}

.accordion.style-2 > ul > li.active > div {
  border-bottom: 1px solid #eee;
}

/* Tabs */
.tabs .tab-nav > li a {
  padding: 1.3rem 2.5rem;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  color: $grey-60;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
}

.tabs .tab-nav > li a:hover {
  background-color: #ddd;
  border-color: #ddd;
}

.tabs .tab-nav > li,
.tabs.vertical .tab-nav > li {
  margin: 0;
}

.tabs .tab-nav > li.active a,
.tabs .tab-nav > li.active a:hover {
  color: #232323;
}

.tabs .tab-panes {
  padding: 3rem;
}

.tabs[class*="style-"] .tab-nav > li {
  margin: 0 !important;
}

.tabs[class*="style-"] .tab-panes {
  border: none;
}

.tabs[class*="style-"]:not(.style-3) .tab-panes {
  padding: 5rem 0 0 0;
  border: none;
  background: none;
}

.tab-panes > div.active {
  overflow: visible;
}

.tabs.style-1.vertical.right .tab-panes,
.tabs.style-2.vertical.right .tab-panes {
  padding-right: 2.5rem;
  margin: 0 !important;
}

/* style 1 */
.tabs.style-1.vertical .tab-nav > li,
.tabs.style-1.vertical.right .tab-nav > li {
  margin-bottom: 0;
}

.tabs.style-1 .tab-nav > li a,
.tabs.style-1.vertical .tab-nav > li a {
  background-color: #f4f4f4;
  border: none;
  color: $grey-60;
}

.tabs.style-1 .tab-nav > li a:hover {
  background-color: #ddd;
  border: none;
  color: $grey-60;
}

.tabs.style-1 .tab-nav > li.active a,
.tabs.style-1 .tab-nav > li.active a:hover,
.tabs.style-1.vertical.right .tab-nav > li.active a,
.tabs.style-1.vertical.right .tab-nav > li.active a:hover {
  margin-right: 0;
  border: none;
  background-color: $brand;
  color: $white;
}

/* style 2 */
.tabs.style-2 .tab-nav > li a,
.tabs.style-2.vertical .tab-nav > li a,
.tabs.style-2.vertical.right .tab-nav > li a {
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-color: #eee;
  color: $grey-60;
}

.tabs.style-2:not(.vertical) .tab-panes {
  border-top: 1px solid #eee;
}

.tabs.style-2 .tab-nav > li.active a,
.tabs.style-2 .tab-nav > li.active a:hover,
.tabs.style-2.vertical .tab-nav > li.active a,
.tabs.style-2.vertical.right .tab-nav > li.active a {
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-color: $brand;
  color: $brand;
}

/* style 3 */
.tabs.style-3 .tab-nav > li a,
.tabs.style-3.vertical .tab-nav > li a,
.tabs.style-3.vertical.right .tab-nav > li a,
.tabs.style-3 .tab-panes {
  background-color: transparent;
  border-width: 0;
  color: $grey-60;
}

.tabs.style-3 .tab-nav > li.active a,
.tabs.style-3 .tab-nav > li.active a:hover,
.tabs.style-3.vertical .tab-nav > li.active a,
.tabs.style-3.vertical.right .tab-nav > li.active a {
  background-color: transparent;
  border: none;
  color: $brand;
}

.tabs.style-3:not(.vertical) .tab-nav > li a {
  padding: 1rem 0;
  margin-right: 3rem;
}

@media only screen and (min-width: 768px) {
  .tabs.style-1.vertical .tab-panes {
    padding: 0 0 0 3.5rem;
  }
  .tabs.style-1.vertical .tab-panes,
  .tabs.style-2.vertical .tab-panes {
    padding: 0 0 0 3.5rem;
    margin: 0 !important;
  }
  .tabs.style-1.vertical.right .tab-panes,
  .tabs.style-2.vertical.right .tab-panes {
    padding: 0 3.5rem 0 0;
    margin: 0 !important;
  }
  .tabs.style-2.vertical .tab-nav > li a {
    padding-left: 0;
    border: none;
    border-right: 1px solid #eee;
  }
  .tabs.style-2.vertical.right .tab-nav > li a {
    padding-left: 2.5rem;
    padding-right: 0;
    border: none;
    border-left: 1px solid #eee;
  }
  .tabs.style-2.vertical .tab-nav > li.active a,
  .tabs.style-2.vertical .tab-nav > li.active a:hover {
    border: none;
    border-right: 1px solid $brand;
  }
  .tabs.style-2.vertical.right .tab-nav > li.active a,
  .tabs.style-2.vertical.right .tab-nav > li.active a:hover {
    border: none;
    border-left: 1px solid $brand;
  }
  .tabs.style-3.center:not(.vertical) .tab-nav > li a {
    margin: 0 1.5rem 0 1.5rem;
  }
}

.tabs.style-3.right:not(.vertical) .tab-nav > li a {
  margin: 0 0 0 3rem;
}

.tabs.style-3 .tab-panes {
  padding: 3rem 0 0 0;
}

/* Menus */
.menu-list h4 {
  font-size: 1.4rem;
}

.menu-list li:nth-child(even) h4,
.menu-list li:nth-child(even) .menu-description {
  color: $grey-60;
}

.menu-list h4 .menu-line {
  bottom: 1px;
}

.menu-list .menu-content .menu-line {
  bottom: 8px;
}

.menu-item .menu-title,
.menu-item .menu-description,
.menu-item .menu-price {
  background: none;
}

.menu-item.dotted .menu-title,
.menu-item.dotted .menu-description,
.menu-item.dotted .menu-price,
.menu-item.dashed .menu-title,
.menu-item.dashed .menu-description,
.menu-item.dashed .menu-price {
  background-color: $white;
}

.dotted .menu-line {
  background-image: radial-gradient(circle closest-side, #ccc 99%, rgba(0, 0, 0, 0) 0%);
}

.dashed .menu-line {
  background-image: linear-gradient(to right, #ccc 50%, rgba(0, 0, 0, 0) 0%);
}

.section-block.map-wrapper {
  padding-top: 0;
  padding-bottom: 0
}

.map-container {
  width: 100%;
  height: 40rem
}

.map-container>div {
  width: 100%;
  height: 100%
}

.map-container>div img {
  max-width: none
}
