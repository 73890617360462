.background-fixed,
.background-fixed:before,
.background-fixed:after{
  background-attachment: fixed;
}
.mobile .background-fixed,
.mobile .background-fixed:before,
.mobile .background-fixed:after{
  background-attachment: scroll;
}
.background-cover,
.background-cover:before,
.background-cover:after,
.fullscreen-section.background-cover .background-image{
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}
.background-contain,
.background-contain:before,
.background-contain:after,
.fullscreen-section.background-contain .background-image{
  background-position: center center;
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}
.background-full,
.fullscreen-section.background-full .background-image{
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.background-none{
  background: none !important;
}
.background-image-none{
  background-image: none !important;
}