
.tm-slider-container:not([class*="bkg-"]) {
  background: none;
}

/* Slider Heights */
.featured-media,
.full-width-slider,
.parallax {
  height: 60rem;
  padding: 0;
}

.window-height {
  height: 100vh;
}

.hero-slider.window-height {
  min-height: 100vh;
}

.parallax.page-intro,
.page-intro {
  height: 45rem;
}

.parallax.small,
.page-intro.small {
  height: 30rem;
}

.page-intro .full-width-slider,
.window-height .full-width-slider {
  height: inherit;
}

.logo-slider,
.testimonial-slider,
.team-slider,
.recent-slider .thumbnail {
  min-height: 5rem;
}

.recent-slider {
  min-height: 33rem;
}

.team-slider {
  height: 42rem;
}

.hero-slider {
  min-height: 60rem;
}

/* Mobile Slider & Parallax */
.mobile .featured-media,
.mobile .parallax.fixed-height {
  height: 40rem;
}

/* Slider Navigation Common */
.tms-arrow-nav {
  width: 4.4rem;
  height: 4.4rem;
  background: none;
  font-size: 2rem;
  line-height: 4.4rem;
  color: $white;
  border: none;
  background-color: $black;
}

.tms-arrow-nav:hover {
  opacity: 0.5 !important;
}

.tms-arrow-nav:before {
  line-height: 4.4rem;
}

.tms-arrow-nav:hover {
  width: 4.4rem;
}

.tms-bullet-nav {
  background-color: $white;
}

.tms-nav-dark .tms-bullet-nav {
  background-color: $black;
}

/* Featured Media Slider Navigation */
.featured-media .tms-pagination {
  width: 100%;
  bottom: 3rem;
}

.featured-media .tms-pagination a {
  display: inline-block;
  margin-bottom: 1rem;
}

.featured-media .tm-slider-container .tms-pagination {
  opacity: 1;
}

/* Testimonial Slider */
.testimonial-slider {
  height: 14rem;
}

.testimonial-slider blockquote h4 {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}

/* Aux API Nav */
.testimonial-slider-aux-nav .avatar,
.testimonial-slider-aux-nav .content-aside {
  display: inline-block;
}

.testimonial-slider-aux-nav .content-aside span {
  display: block;
}

.testimonial-slider-aux-nav .avatar {
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
  position: relative;
  top: -1.4rem;
}

.testimonial-slider-aux-nav img {
  border-radius: 50%;
}

.testimonial-slider-aux-nav a.active .h5 {
  color: $brand;
}

/* Recent Slider */
.recent-carousel.replicable-content {
  padding-top: 4rem;
  padding-bottom: 1rem;
}

.recent-carousel .column.slider-column {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tm-slider-container.tms-carousel > ul {
  opacity: 1;
}

/* Team Slider */
.team-slider li {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.team-slider p {
  line-height: inherit;
}

/* Slider Buttons */
.tm-slider-container .button {
  font-size: 1.1rem;
  font-weight: 400;
}

/* Featured Slider Nav Breakpoints */
@media only screen and (max-width: 768px) {
  .featured-media .tms-pagination a {
    display: inline-block;
  }
  .hero-slider .hero-content {
    background-color: rgba(0, 0, 0, 0.4) !important;
    color: $white !important;
  }
  .hero-slider .tms-bullet-nav {
    background-color: $white;
  }
}
