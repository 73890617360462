$white: #fff;
$ghost-white: #f9fcfd;

$blue: #368ac1;
$light-blue: #86bbd8;
$dark-blue: #28658d; // #264653
$orange: #ffb400;
$red: #e04a1b;
$green: #08605f;

$ghost-grey: #eff1f6;
$grey-20: #cdcfd0;
$grey-40: #9b9fa2;
$grey-60: #6b7174;
$grey-80: #394145;

$black: #061016;
$off-black: $grey-80;

$brand: $blue;

$border-radius-small: 3px;
$border-radius-med: 8px;
$border-radius-large: 14px;

$border-radius: $border-radius-small;

$box-shadow: 0 2px 2px 0 rgba($black, .1), 0 1px 5px 0 rgba($black, .12), 0 3px 1px -2px rgba($black, .12);

// Typography

$font-stack: 'Roboto', 'Helvetica Neue', sans-serif;
$header-font-stack: 'Bitter', 'Helvetica Neue', sans-serif;
$alternate-font-stack: 'Playfair Display', serif;

$h1-fontsize: 2rem !default;
$h2-fontsize: 2rem !default;
$h3-fontsize: 1.92rem !default;
$h4-fontsize: 1.28rem !default;
$h5-fontsize: 1rem !default;
$h6-fontsize: 1rem !default;
