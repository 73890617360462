.testimonial-1 h6{
  margin-bottom: 4rem;
  font-size: 1.3rem;
  text-transform: uppercase;
}
.testimonial-1 blockquote{
  width: 70%;
  padding: 0;
  margin-bottom: 0;
}
.testimonial-1 blockquote.avatar span,
.testimonial-1 blockquote.avatar span img{
  width: 9rem;
  height: 9rem;
}
.testimonial-1 p{
  margin-top: 3rem;
}
.testimonial-1 cite{
  text-transform: uppercase;
  font-weight: bold;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.1rem;
}

/* Testimonial 2 */
.testimonial-2 h6{
  margin-bottom: 1rem;
  font-size: 1.3rem;
  text-transform: uppercase;
}
.testimonial-2 .brief{
  height: 100%;
  border-right: 1px solid #ddd;
  display: table;
}
.testimonial-2 .brief p:last-child{
  margin-bottom: 0;
}
.testimonial-2 .brief-inner{
  display: table-cell;
  vertical-align: middle;
}
.testimonial-2 blockquote{
  width: 100%;
  padding: 0;
  margin-bottom: 3rem;
}
.testimonial-2 blockquote:last-child{
  margin-bottom: 0;
}
.testimonial-2 cite{
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0.1rem;
}

/* Testimonial 3 */
.testimonial-3 [class*="content-grid"]{
  margin-bottom: -3rem;
}
.testimonial-3 h6{
  margin-bottom: 4rem;
  font-size: 1.3rem;
  text-transform: uppercase;
}
.testimonial-3 blockquote{
  width: 100%;
  padding: 0;
  margin: 0;
}
.testimonial-3 cite{
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

/* Testimonial 4 */
.testimonial-4 [class*="content-grid"]{
  margin-bottom: -3rem;
}
.testimonial-4 h6{
  margin-bottom: 4rem;
  font-size: 1.3rem;
  text-transform: uppercase;
}
.testimonial-4 blockquote{
  height: 100%;
  padding: 2.5rem;
  margin: 0;
  border-radius: 0.3rem;
  border: 1px solid #ddd;
}
.testimonial-4 cite{
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

/* Testimonial 5 */
.testimonial-5{
  color: $white;
  background-image:url(../images/slider/slide-2-fs-hp.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
}
.testimonial-5 blockquote{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  float: none;
}
.testimonial-5 cite{
  text-transform: uppercase;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0.1rem;
}
.testimonial-slider{
  width: 100% !important;
  min-height: 10rem;
  margin: 0 auto;
  background: none;
}
.testimonial-slider .tms-pagination{
  bottom: 0;
}
.testimonial-slider.left,
.testimonial-slider.right{
  margin: 0;
}
.testimonial-slider blockquote,
.center .testimonial-slider blockquote{
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.testimonial-slider.left blockquote,
.testimonial-slider.left .tms-pagination{
  text-align: left;
}
.testimonial-slider.left blockquote{
  padding-left: 0;
  padding-right: 1rem;
}
.testimonial-slider.right blockquote,
.testimonial-slider.right .tms-pagination{
  text-align: right;
}
.testimonial-slider.right blockquote{
  padding-left: 1rem;
  padding-right: 0;
}
.testimonial-slider blockquote span{
  margin-top: 0rem;
  margin-bottom: 1.5rem;
}

/* Testimonial Media Queries */
@media only screen and (max-width: 960px){
  .testimonial-1 p,
  .testimonial-5 .testimonial-slider p{
    font-size: 1.9rem;
  }
}
@media only screen and (max-width: 768px){
  .testimonial-2 .brief{
    border-right: none;
  }
  .testimonial-2 .brief h6:last-child,
  .testimonial-2 .brief p:last-child{
    margin-bottom: 3rem;
  }
  .testimonial-3 blockquote{
    height: 100%;
    padding-bottom: 3rem;
    border-bottom: 1px solid #ddd;
  }
  .testimonial-3 .grid-item:last-child blockquote{
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
  .testimonial-slider.center-on-mobile{
    margin: 0 auto;
  }
  .testimonial-slider.center-on-mobile blockquote,
  .testimonial-slider.center-on-mobile .tms-pagination{
    text-align: center;
  }
  .testimonial-slider.center-on-mobile blockquote{
    padding: 0 !important;
  }
}
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (		 min-device-pixel-ratio: 2),
only screen and (			min-resolution: 192dpi),
only screen and (				min-resolution: 2dppx) {
  .testimonial-5{
    background-image:url(../images/slider/slide-2-fs-hp@2x.jpg);
  }
}