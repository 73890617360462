.content {
  transition-duration: 500ms;
}

.animate-content {
  transform: scale(0.95) translateY(0px);
  transition-duration: 500ms;
}

.section-block,
.fullscreen-section .fullscreen-inner,
.pagination-2 .pagination-next,
.row.xlarge {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.section-block.section-first {
  padding-top: 9rem;
  border-bottom: 1px solid $grey-40;
}

@media screen and (max-width: 768px) {
  .section-block.section-first {
    padding-top: 6rem;
    border-bottom: 1px solid $grey-40;
  }
}

.replicable-content,
.row.xlarge.replicable-content {
  padding-top: 11rem;
  padding-bottom: 8rem;
}

.section-block.slanted-top:before {
  width: 100%;
  height: 12rem;
  background-color: inherit;
  content: '';
  position: absolute;
  left: 0;
  top: -5.7rem;
  -webkit-transform: skewY(-4.5deg);
  -ms-transform: skewY(-4.5deg);
  transform: skewY(-4.5deg);
  z-index: 5;
}

.grid-filter-menu {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.section-block.feature-2 {
  padding-bottom: 12rem;
}

.divider {
  margin-top: 6rem;
  margin-bottom: 9rem;
}

.separator {
  width: 5rem;
  height: 0.2rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

.text-line {
  width: 2rem;
  height: 0.1rem;
  display: inline-block;
}

.text-line[class*="bkg-"] {
  padding: 0;
}

.text-line-vertical {
  display: inline-block;
  position: relative;
}

.text-line-vertical:after {
  width: 1px;
  height: 8rem;
  margin-top: 3rem;
  border-right: 1px solid #777;
  position: absolute;
  top: 100%;
  left: 50%;
  content: '';
}

.tm-slider-container .text-line-vertical:after {
  height: 200%;
  border-color: $white;
}

@media only screen and (min-width: 960px) {
  .header-1-static + .content {
    padding-top: 8rem;
  }
}

@media only screen and (max-width: 768px) {
  .fullscreen-section.content-below-on-mobile .fullscreen-inner {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
}