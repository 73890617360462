.header {
  width: 100%;
  position: relative;

  .header-inner {
    width: 100%;
    max-width: 100%;
    opacity: 1;
    position: relative;
    z-index: 100;
    /* Force new layer*/
    -webkit-transform: translateZ(0);

    background-color: $blue;

  }
}

.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.header .header-inner > .nav-bar {
  margin-bottom: -1px;
}

.nav-bar .logo {
  float: left;
}

.header-transparent .header-inner {
  background-color: transparent;
  /* firefox fix for cases where header has a fixed
  position and translate is used to animate elements on page
  - fixes jaggy transitions in ff */
  background-image: url(../images/blank.png);
  border-bottom: 1px solid transparent;
}

/* Bkg Color Header Classes */
.mobile .header-transparent .header-inner,
.header-transparent.header-background .header-inner {
  background: $blue;
  border-bottom: 1px solid $dark-blue;
}

/* Intial Height */
.header .logo,
.header .header-inner .navigation > ul > li,
.header .header-inner .navigation > ul > li > a:not(.button) {
  height: 8rem;
  line-height: 8rem;
}

/* Compacted Height */
.header-compact .logo,
.header-compact .header-inner .navigation > ul > li,
.header-compact .header-inner .navigation > ul > li > a:not(.button) {
  height: 6rem;
  line-height: 6rem;
}

/* Logo */
.header .logo,
.header .header-inner .navigation > ul > li {
  display: table;
}

@media screen and (min-width: 961px) {
  .header:not(.header-transparent) + .content {
    padding-top: 8rem;
  }
}

@import "header/animated";
@import "header/logo";
@import "header/navigation";
@import "header/mobile";