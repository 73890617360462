.tm-lightbox {
  background: rgba(255, 255, 255, 1);
}

#tml-content-wrapper.zoomed + #tml-caption span {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  color: $white;
}

/* Standard Modal Mode */
#tm-lightbox.tml-modal-mode {
  background: rgba(0, 0, 0, 0.1);
}

.tml-modal-mode .modal-dialog {
  box-shadow: 0 0 4rem rgba(0, 0, 0, .5);
}

.tml-modal-mode #tml-exit {
  color: #33363a;
}

.tml-exit-light #tml-exit {
  color: $white;
}

.rounded .modal-dialog,
.rounded .section-block {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

/* Common Modal Mode */
.tml-contact-modal .modal-dialog > div,
.tml-newsletter-modal .modal-dialog > div,
.tml-promotion-modal .modal-dialog > div {
  padding-left: 0;
  padding-right: 0;
}

.tml-contact-modal .contact-form-container,
.tml-newsletter-modal .signup-form-container {
  padding-left: 3rem;
  padding-right: 3rem;
}

/* greyscale upon modal opening */
.modal-open .wrapper-inner {
  -webkit-filter: grayscale(70%);
  filter: grayscale(70%);
}

@media only screen and (max-width: 960px) {
  .tml-padding-small #tml-content-wrapper {
    left: 5% !important;
    right: 5% !important;
  }
}

@media only screen and (max-width: 768px) {
  .tml-modal-mode .modal-dialog {
    width: 100% !important;
  }
  .tml-modal-mode .modal-dialog > div {
    padding-left: 0;
    padding-right: 0;
  }
  .tml-swap-exit-light #tml-exit {
    color: $white;
  }
  .tml-modal-mode .hero-5 {
    padding-bottom: 2rem;
  }
  .tml-modal-mode .hero-5 .column:first-child:not(.media-column) .split-hero-content {
    margin-top: 4rem;
  }
}

/* Navigation */
.tml-nav {
  color: #999;
  opacity: 0.7;
}

.tml-nav:hover {
  color: $black;
}