.header .navigation > ul > li > a {
  margin: 0 1.75rem;
  padding-left: 0;
  padding-right: 0;

  font-size: 1.3rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1rem;

  color: $white;
  opacity: 0.8;
}

.header .navigation > ul > li.current > a,
.header .navigation > ul > li > a:hover {
  color: $white;
  opacity: 1;
}
